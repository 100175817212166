import React, { useCallback, useEffect, useState } from 'react';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import {
	NotificationInterface,
	OrderInterface,
} from '../../../components/OrdersTable/interfaces/Order.interface';
import AssignOrder from './components/Steps/AssignOrder';
import TransporterConfirmed from './components/Steps/TransporterConfirmed';
import AwaitingTransport from './components/Steps/AwaitingTransport';
import TruckConfirmed from './components/Steps/TruckConfirmed';
import Delivered from './components/Steps/Delivered';
import ToLoading from './components/Steps/ToLoading';
import { NotificationsService } from '../../../services/notifications.services';
import Emitter, { EventType } from '../../../services/events';
import { T } from '@tolgee/react';
import Timeline from './components/Timeline';
import DocumentsTab from '../../../components/DocumentsTab/DocumentsTab';
import {
	AdminOrderStatusesEnum,
	OrderStatesEnum,
	OrderStatusesEnum,
} from '../../../services/orders.services';
import FillerStep from './components/Steps/FillerStep';
import PendingPayment from './components/Steps/PendingPayment';

export interface OrderDetailsProps {
	isOpen: boolean;
	selectedOrder: OrderInterface | undefined;
}

const OrderDetails: React.FC<OrderDetailsProps> = (props) => {
	const { isOpen = false, selectedOrder } = props;
	const [order, setOrder] = useState<OrderInterface | undefined>(selectedOrder);
	const [currentTab, setCurrentTab] = useState<string>('overview');
	const [notifications, setNotifications] = useState<NotificationInterface[]>(
		[],
	);

	const selectOrder = (order: any) => {
		setOrder(order);
	};

	useEffect(() => {
		setOrder(selectedOrder);
		setCurrentTab('overview');
	}, [selectedOrder]);

	const handleClick = (tab: string) => {
		setCurrentTab(tab);
	};

	useEffect(() => {
		const notificationList: NotificationInterface[] = selectedOrder
			?.notifications?.items?.length
			? selectedOrder?.notifications?.items?.reduce(
					(t: NotificationInterface[], r: NotificationInterface) => {
						const exist = t?.some((c) => c?.category === r.category);
						if (!exist) {
							return [...t, r];
						}
						return t;
					},
					[],
			  )
			: [];
		setNotifications(notificationList);
	}, [selectedOrder?.notifications?.items]);

	const markReadNotifications = useCallback(async () => {
		const notificationIds = selectedOrder?.notifications?.items
			?.filter((r) => !r.readAt)
			?.map((r) => r.id);
		try {
			if (notificationIds?.length) {
				await NotificationsService.readNotify(notificationIds);
				Emitter.emit(EventType.NOTIFICATION_COUNT_DECREASE, 1);
			}
			Emitter.emit(
				EventType.ORDER_NOTIFICATION_COUNT_DECREASE,
				selectedOrder?.id!,
			);
		} catch (e) {}
	}, [selectedOrder]);

	useEffect(() => {
		if (selectedOrder?.id) {
			markReadNotifications();
		}
	}, [markReadNotifications, selectedOrder?.id]);

	const renderSimpleSteps = (order: OrderInterface) => {
		const { state, status } = order;

		if (status === OrderStatusesEnum.PAID) {
			return <Delivered selectedOrder={order} />;
		}

		if (
			order?.trucks?.[0].driver &&
			state === OrderStatesEnum.TRANSPORTER_CONFIRMED
		) {
			return <TruckConfirmed selectedOrder={order} />;
		}

		switch (state) {
			case OrderStatesEnum.TO_DO:
				return <AssignOrder selectedOrder={order} setOrder={selectOrder} />;
			case OrderStatesEnum.AWAITING_CONFIRMATION:
				return <AwaitingTransport selectedOrder={order} />;
			case OrderStatesEnum.TRANSPORTER_CONFIRMED:
				return <TransporterConfirmed selectedOrder={order} />;
			case OrderStatesEnum.TRUCK_CONFIRMED:
				return <TruckConfirmed selectedOrder={order} />;
			case OrderStatesEnum.TO_LOADING:
				return <ToLoading selectedOrder={order} />;
			case OrderStatesEnum.ARRIVED_TO_LOADING:
			case OrderStatesEnum.LOADING:
				return <FillerStep selectedOrder={order} currentStep={6} />;
			case OrderStatesEnum.LOADED:
			case OrderStatesEnum.TO_UNLOADING:
				return <FillerStep selectedOrder={order} currentStep={7} />;
			case OrderStatesEnum.ARRIVED_TO_UNLOADING:
				return <FillerStep selectedOrder={order} currentStep={8} />;
			case OrderStatesEnum.UNLOADING:
				return <FillerStep selectedOrder={order} currentStep={9} />;
			case OrderStatesEnum.UNLOADED:
				return <FillerStep selectedOrder={order} currentStep={10} />;
			case OrderStatesEnum.COMPLETED:
				return <PendingPayment selectedOrder={order} />;
			default:
				return null;
		}
	};

	const renderMultipleSteps = (order: OrderInterface) => {
		const { state, status } = order;

		const isStateInRange = [
			OrderStatesEnum.TRUCK_CONFIRMED,
			OrderStatesEnum.TO_LOADING,
			OrderStatesEnum.ARRIVED_TO_LOADING,
			OrderStatesEnum.LOADING,
			OrderStatesEnum.LOADED,
			OrderStatesEnum.TO_UNLOADING,
			OrderStatesEnum.ARRIVED_TO_UNLOADING,
			OrderStatesEnum.UNLOADING,
			OrderStatesEnum.UNLOADED,
			OrderStatesEnum.COMPLETED,
		].includes(state);

		if (state === OrderStatesEnum.COMPLETED) {
			if (status === AdminOrderStatusesEnum.DELIVERED) {
				return <PendingPayment selectedOrder={order} />;
			} else if (status === OrderStatusesEnum.PAID) {
				return <Delivered selectedOrder={order} />;
			}
		}

		if (
			isStateInRange &&
			status === AdminOrderStatusesEnum.AWAITING_CONFIRMATION
		) {
			return <TransporterConfirmed selectedOrder={order} />;
		}

		switch (status) {
			case AdminOrderStatusesEnum.TO_DO:
				return <AssignOrder selectedOrder={order} setOrder={selectOrder} />;
			case AdminOrderStatusesEnum.AWAITING_CONFIRMATION:
				return <AwaitingTransport selectedOrder={order} />;
			case AdminOrderStatusesEnum.TRANSPORTER_CONFIRMED:
			case AdminOrderStatusesEnum.TRUCK_CONFIRMED:
			case AdminOrderStatusesEnum.IN_PROGRESS:
			case AdminOrderStatusesEnum.TO_LOADING:
			case AdminOrderStatusesEnum.TO_UNLOADING:
				return <TransporterConfirmed selectedOrder={order} />;

			default:
				return null;
		}
	};

	const renderSimpleExcluded = (order: OrderInterface) => {
		const statusComponentMap: { [key: string]: JSX.Element } = {
			[AdminOrderStatusesEnum.TO_DO]: (
				<AssignOrder selectedOrder={order} setOrder={selectOrder} />
			),
			[AdminOrderStatusesEnum.AWAITING_CONFIRMATION]: (
				<AwaitingTransport selectedOrder={order} />
			),
			[AdminOrderStatusesEnum.IN_PROGRESS]: (
				<TransporterConfirmed selectedOrder={order} />
			),
			[AdminOrderStatusesEnum.TO_LOADING]: (
				<TruckConfirmed selectedOrder={order} />
			),
			[AdminOrderStatusesEnum.DELIVERED]: (
				<PendingPayment selectedOrder={order} />
			),
			[OrderStatusesEnum.PAID]: <Delivered selectedOrder={order} />,
		};

		return statusComponentMap[order.status] || null;
	};

	const renderMultipleExcluded = (order: OrderInterface) => {
		const statusComponentMap: { [key: string]: JSX.Element } = {
			[AdminOrderStatusesEnum.TO_DO]: (
				<AssignOrder selectedOrder={order} setOrder={selectOrder} />
			),
			[AdminOrderStatusesEnum.AWAITING_CONFIRMATION]: (
				<AwaitingTransport selectedOrder={order} />
			),
			[AdminOrderStatusesEnum.IN_PROGRESS]: (
				<FillerStep selectedOrder={order} currentStep={3} />
			),
			[AdminOrderStatusesEnum.TO_LOADING]: (
				<FillerStep selectedOrder={order} currentStep={3} />
			),
			[AdminOrderStatusesEnum.DELIVERED]: (
				<FillerStep selectedOrder={order} currentStep={3} />
			),
		};

		const hasProducerLoadDetails = order?.trucks?.every(
			(obj) => 'producerLoadDetails' in obj,
		);
		const isPaid = order?.status === OrderStatusesEnum.PAID;

		if (hasProducerLoadDetails) {
			return isPaid ? (
				<Delivered selectedOrder={order} />
			) : (
				<PendingPayment selectedOrder={order} />
			);
		}

		return statusComponentMap[order.status] || null;
	};

	const renderOverviewTab = (order: OrderInterface | undefined) => {
		if (!order) return null;
		const { trucks, deliveryIncluded } = order;
		const simpleOrder = trucks?.length === 1;

		return simpleOrder
			? deliveryIncluded
				? renderSimpleSteps(order)
				: renderSimpleExcluded(order)
			: deliveryIncluded
			? renderMultipleSteps(order)
			: renderMultipleExcluded(order);
	};

	return (
		<>
			{isOpen ? (
				<div className="w-[716px] h-full flex flex-col absolute top-0 right-0 bg-alabaster shadow-order-details z-[100] overflow-y-auto scroll-smooth rounded-2xl">
					<div className="px-10 py-7 flex gap-[4px]">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
									currentTab === 'overview'
										? 'bg-primary-violet text-white-100'
										: 'hover:bg-gray-10 bg-alabaster text-gray-80'
								}`}
								onClick={() => handleClick('overview')}
							>
								<SypacIcon
									iconName="Document Text"
									size="custom"
									width="24px"
									height="24px"
									className="mb-[2px]"
								/>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="tabButton.general">General</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
									currentTab === 'documents'
										? 'bg-primary-violet text-white-100'
										: 'hover:bg-gray-10 bg-alabaster text-gray-80'
								}`}
								onClick={() => setCurrentTab('documents')}
							>
								<SypacIcon
									iconName="Folder"
									size="custom"
									width="24px"
									height="24px"
									className="mb-[2px]"
								/>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="tabButton.documents">Documents</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
									currentTab === 'events'
										? 'bg-primary-violet text-white-100'
										: 'hover:bg-gray-10 bg-alabaster text-gray-80'
								}`}
								onClick={() => setCurrentTab('events')}
							>
								<SypacIcon
									iconName="Bell"
									size="custom"
									width="24px"
									height="24px"
									className="mb-[2px]"
								/>
								<SypacText variant="body-regular-medium" className="flex gap-4">
									<p>
										<T keyName="tabButton.events">Events</T>
									</p>
									{notifications.length > 2 ? (
										<div
											className={`w-fit px-2 flex justify-center rounded-full text-gray-80 transition ${
												currentTab === 'events'
													? 'bg-white'
													: 'bg-gray-10 group-hover:bg-white'
											}`}
										>
											{notifications.length - 2}
										</div>
									) : null}
								</SypacText>
							</button>
						</SypacButton>
					</div>
					<div className="flex flex-col h-full overflow-x-hidden overflow-y-scroll">
						{currentTab === 'overview' && renderOverviewTab(order)}

						{currentTab === 'events' ? (
							<Timeline selectedOrder={selectedOrder} />
						) : null}

						{currentTab === 'documents' ? (
							<div className="flex flex-col gap-8 pt-8 border-0 border-solid border-t-[1px] border-gray-10">
								<DocumentsTab selectedOrder={selectedOrder} />
							</div>
						) : null}
					</div>
				</div>
			) : null}
		</>
	);
};

export default OrderDetails;
