import { SypacText } from '@sypac/component-library-react';
import classNames from 'classnames';
import { useState } from 'react';

import MenuItems from './MenuItems';
import { ChevronRightIcon } from '../../../../assets/ChevronRightIcon';
import { VariantInterface } from '../../ProductLocation';

interface MultipleSelectInterface {
	items: {
		title: string;
		variant: string;
		submenu?: {
			title: string;
			variant: string;
		}[];
	}[];
	selected: VariantInterface[];
	setSelected: any;
	setChecked: any;
	checkeds: string[];
}

export const MultipleSelect = ({
	items,
	selected,
	setSelected,
	setChecked,
	checkeds,
}: MultipleSelectInterface) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleMouseLeave = () => {
		setIsOpen(false);
	};

	return (
		<div
			className="relative"
			onMouseEnter={() => setIsOpen(true)}
			onMouseLeave={() => setIsOpen(false)}
		>
			<div
				className="border border-solid border-gray-10 rounded-md py-2.5 pl-3 pr-4 flex justify-between truncate"
				onClick={() => setIsOpen(!isOpen)}
			>
				<SypacText variant="body-normal-small">
					<p>{selected[0].title}</p>
				</SypacText>
				<span
					className={classNames('flex my-auto transition-all', {
						'transform rotate-90': isOpen,
					})}
				>
					<ChevronRightIcon />
				</span>
			</div>
			{isOpen && (
				<div className="absolute left-0 top-full pt-2.5 z-20">
					<ul className="menus bg-white flex flex-col p-3 w-[256px] rounded-lg">
						{items.map((menu: any, index: number) => {
							const depthLevel = 0;
							return (
								<MenuItems
									items={menu}
									selected={selected}
									selectedItems={checkeds}
									setSelected={setSelected}
									setChecked={setChecked}
									key={index}
									depthLevel={depthLevel}
									handleMouseLeave={handleMouseLeave}
								/>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};
