import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	SypacBadge,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { OrderInterface } from '../OrdersTable/interfaces/Order.interface';
import { T, useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';
import ExpandedBox from '../ExpandedBox/ExpandedBox';
import { BlueBox } from '../../assets/BlueBox';
import { TruckApprove } from '../../assets/TruckApprove';
import { ExpandedBoxType } from '../ExpandedBox/ExpandedBox.interface';
import { Stack } from '../../assets/Stack';
import { TruckNotApprove } from '../../assets/TruckNotApprove';
import {
	OrderTarget,
	PaymentMethod,
} from '../OrdersTable/interfaces/OrderStatus.interface';
import StatusBadge from '../StatusBadge/StatusBadge';
import { Tooltip } from 'react-tooltip';
import { Calendar } from '../../assets/Calendar';
import Invoice from '../../assets/Invoice';
import { OrderStatusesEnum } from '../../services/orders.services';
import { BillingService } from '../../services/billing.service';
import { InvoiceInterface } from '../PaymentsTable/interfaces/Payment.interface';
import { PaymentStatusColor } from '../PaymentStatus/PaymentStatus.constants';
import { PaymentTypeAction } from '../PaymentsTable/interfaces/PaymentStatus.interface';
import DocumentsViewer from '../DocumentsViewer/DocumentsViewer';
import { AuthContext } from '../../context/context';
import { calculateRemainingDays } from '../../utils/time.util';
import { PaymentStatusEnum } from '../PaymentStatus/PaymentStatus.enum';

export interface BaseOrderDetailsProps {
	selectedOrder: OrderInterface | undefined;
	isOpen: boolean;
	simpleOrder: boolean;
	deliveryIncluded: boolean;
	target: string;
}

let euro = Intl.NumberFormat('pl', {
	style: 'currency',
	currency: 'PLN',
});

const BaseOrderDetails: React.FC<BaseOrderDetailsProps> = (props) => {
	const { t } = useTranslate();
	const { isOpen, selectedOrder, simpleOrder, deliveryIncluded, target } =
		props;
	const [orderInvoice, setOrderInvoice] = useState<
		InvoiceInterface | undefined
	>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [invoiceUrl, setInvoiceUrl] = useState<string | undefined>(undefined);
	const [openView, setShowView] = useState<boolean>(false);
	const { user } = useContext(AuthContext);

	const getInvoice = useCallback(async () => {
		setIsLoading(true);
		try {
			const target =
				selectedOrder?.target === OrderTarget.admin
					? OrderTarget.customer
					: selectedOrder?.target;

			if (selectedOrder?.target === OrderTarget.admin) {
				const { data } = await BillingService.getInvoiceForAdmin(
					target!,
					selectedOrder?.id!,
				);
				setOrderInvoice(data);
			} else {
				const { data } = await BillingService.getInvoiceById(
					target!,
					selectedOrder?.id!,
				);
				setOrderInvoice(data);
			}
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	}, [selectedOrder?.id, selectedOrder?.target]);

	useEffect(() => {
		if (!selectedOrder) {
			setOrderInvoice(undefined);
		} else {
			setOrderInvoice(undefined);
			getInvoice().then(() => {});
		}
	}, [selectedOrder, getInvoice]);

	const boxType = useMemo(() => {
		const getBoxType = () => {
			if (simpleOrder) {
				return deliveryIncluded
					? ExpandedBoxType.SIMPLE_ORDER
					: ExpandedBoxType.EXCLUDED_ORDER;
			} else {
				return deliveryIncluded
					? ExpandedBoxType.MULTIPLE_ORDER
					: ExpandedBoxType.EXCLUDED_ORDER;
			}
		};

		return getBoxType();
	}, [simpleOrder, deliveryIncluded]);
	const shippingCost = useMemo(
		() => selectedOrder?.transportPriceForClientWithVat || 0,
		[selectedOrder?.transportPriceForClientWithVat],
	);
	const priceNoVat = useMemo(() => {
		let price = 0;
		switch (target) {
			case OrderTarget.admin:
			case OrderTarget.customer:
				price =
					(selectedOrder?.transportPriceForClient ?? 0) +
					(selectedOrder?.productPriceForClient ?? 0);
				break;
			case OrderTarget.transporter:
				price = selectedOrder?.priceForTransporter ?? 0;
				break;
			case OrderTarget.producer:
				price = selectedOrder?.priceForProducer ?? 0;
				break;
			default:
				break;
		}
		return Number(price.toFixed(2));
	}, [
		selectedOrder?.transportPriceForClient,
		selectedOrder?.productPriceForClient,
		selectedOrder?.priceForTransporter,
		selectedOrder?.priceForProducer,
		target,
	]);
	const priceWithVat = useMemo(() => {
		let price = 0;
		switch (target) {
			case OrderTarget.admin:
			case OrderTarget.customer:
				price =
					(selectedOrder?.transportPriceForClientWithVat ?? 0) +
					(selectedOrder?.productPriceForClientWithVat ?? 0);
				break;
			case OrderTarget.transporter:
				price = selectedOrder?.priceForTransporterWithVat ?? 0;
				break;
			case OrderTarget.producer:
				price = selectedOrder?.priceForProducerWithVat ?? 0;
				break;
			default:
				break;
		}
		return Number(price.toFixed(2));
	}, [
		selectedOrder?.transportPriceForClientWithVat,
		selectedOrder?.productPriceForClientWithVat,
		selectedOrder?.priceForTransporterWithVat,
		selectedOrder?.priceForProducerWithVat,
		target,
	]);
	const orderVat = useMemo(() => {
		return parseFloat(
			(Math.abs((priceWithVat - priceNoVat) / priceNoVat) * 100).toFixed(0),
		);
	}, [priceWithVat, priceNoVat]);
	const pricePerTon = useMemo(
		() =>
			target === OrderTarget.customer || target === OrderTarget.admin
				? selectedOrder?.product.pricePerUnitWithProcent ?? 0
				: selectedOrder?.product.pricePerUnit ?? 0,
		[selectedOrder?.product, target],
	);

	const variationPercent = (weight: number, quantity: number) => {
		const result = ((weight - quantity) / quantity) * 100 || 0;
		const percent = Number.isInteger(result)
			? result
			: parseFloat(result.toFixed(2));
		return result > 0 ? `+${percent}` : `${percent}`;
	};

	const InfoRow = ({ label, value, isBadge, badgeContent, className }: any) => (
		<div
			className={`grid grid-cols-[250px,auto] mb-3 items-start ${className}`}
		>
			<SypacText variant="body-regular-medium">
				<p className="text-gray-40">{label}</p>
			</SypacText>
			{isBadge ? (
				badgeContent
			) : (
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">{value}</p>
				</SypacText>
			)}
		</div>
	);

	const BouncingLoader = () => (
		<div className="flex space-x-2">
			<div className="w-3 h-3 bg-primary-violet rounded-full animate-bounce-sequential animation-delay-100" />
			<div className="w-3 h-3 bg-primary-violet rounded-full animate-bounce-sequential animation-delay-200" />
			<div className="w-3 h-3 bg-primary-violet rounded-full animate-bounce-sequential animation-delay-300" />
		</div>
	);

	const downloadInvoice = async (invoice: InvoiceInterface) => {
		try {
			if (!invoice.invoiceUrl) {
				return;
			}

			const companyName = user?.company.name;
			const invoiceSort =
				companyName
					?.split(' ')
					?.map((r) => r[0])
					?.join('')
					?.toLocaleUpperCase() || 'FV';
			const response = await fetch(invoice.invoiceUrl);
			const data = (await response.blob()) as any;
			const downloadUrl = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = downloadUrl;
			const fileName = invoice.invoiceNr
				? `${invoice.invoiceNr}.pdf`
				: `${invoiceSort}${dayjs(invoice.createdAt).format('DD-MM-YYYY')}${
						invoice.id
				  }.pdf`;
			link.setAttribute('download', fileName);

			document.body.appendChild(link);

			link.click();

			link?.parentNode?.removeChild(link);
		} catch (e) {}
	};

	const viewInvoice = (url?: string) => {
		setInvoiceUrl(url);
		setShowView(!!url);
	};

	const actionClick = (type: string, payment: InvoiceInterface) => {
		switch (type) {
			case PaymentTypeAction.download:
				return downloadInvoice(payment);
			case PaymentTypeAction.view_invoice:
				return viewInvoice(payment?.invoiceUrl);
		}
	};

	return (
		<>
			<ExpandedBox
				isOpen={isOpen}
				title={t('baseOrderDetails.orderInfo', 'Order Info')}
				boxType={boxType}
			>
				<div className="flex flex-col">
					<InfoRow
						label={t('baseOrderDetails.orderId', 'Order ID:')}
						value={selectedOrder?.orderId || selectedOrder?.id}
					/>
					<InfoRow
						label={t('baseOrderDetails.created', 'Created:')}
						value={
							<div className="flex items-center gap-3 ml-[5px]">
								<span className="p-0 m-0 flex scale-[1.1]">
									<Calendar />
								</span>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										{dayjs(selectedOrder?.createdAt).format('DD MMM. YYYY')}
									</p>
								</SypacText>
							</div>
						}
					/>
					<InfoRow
						label={t('baseOrderDetails.orderStatus', 'Order status:')}
						isBadge={true}
						badgeContent={<StatusBadge status={selectedOrder?.status!} />}
					/>

					{target === OrderTarget.admin || target === OrderTarget.customer ? (
						<InfoRow
							label={t('baseOrderDetails.orderType', 'Order type:')}
							value={
								<div className="flex items-center gap-3 ml-[5px]">
									<span className="p-0 m-0 flex scale-[1.1]">
										{simpleOrder ? <BlueBox /> : <Stack />}
									</span>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{simpleOrder ? (
												<T keyName="baseOrderDetails.singleDelivery">
													Single delivery
												</T>
											) : (
												<T keyName="baseOrderDetails.multipleDelivery">
													Multiple delivery
												</T>
											)}
										</p>
									</SypacText>
								</div>
							}
						/>
					) : null}

					{target !== OrderTarget.transporter ? (
						<InfoRow
							label={t('baseOrderDetails.deliveryType', 'Delivery type:')}
							value={
								<div className="flex items-center gap-3 ml-[5px]">
									<span className="p-0 m-0 flex scale-[1.1]">
										{deliveryIncluded ? <TruckApprove /> : <TruckNotApprove />}
									</span>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{deliveryIncluded ? (
												<T keyName="baseOrderDetails.transportIncluded">
													Transport included
												</T>
											) : target !== OrderTarget.producer ? (
												<T keyName="baseOrderDetails.transportExcluded">
													Transport excluded
												</T>
											) : (
												<T keyName="baseOrderDetails.selfService">
													Self service
												</T>
											)}
										</p>
									</SypacText>
								</div>
							}
						/>
					) : null}

					<InfoRow
						label={t('baseOrderDetails.productName', 'Product name:')}
						value={selectedOrder?.product.name}
						className="mt-6"
					/>
					{selectedOrder?.product.type && (
						<InfoRow
							label={t('baseOrderDetails.type', 'Type:')}
							value={`${selectedOrder?.product.type} ${
								selectedOrder?.product.size
									? `(${selectedOrder.product.size})`
									: ''
							}`}
						/>
					)}
					{target !== OrderTarget.transporter ? (
						<InfoRow
							label={t('baseOrderDetails.pricePerTon', 'Price per ton:')}
							value={
								<div className="flex items-center gap-3">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">{euro.format(pricePerTon)}</p>
									</SypacText>
									{/* <SypacButton variant="secondary" size="small">
										<button
											className="relative w-full border-none bg-transparent p-0 group"
											type="button"
										>
											<span className="absolute w-[154px] flex items-center gap-1 left-0">
												<SypacIcon
													iconName="Eye"
													size="custom"
													width="28px"
													height="28px"
													className="text-gray"
												/>
												<SypacText variant="body-normal-medium">
													<p className="text-cornflower-blue transition group-hover:text-gray-80">
														View seller price
													</p>
												</SypacText>
											</span>
										</button>
									</SypacButton> */}
								</div>
							}
						/>
					) : null}
					<InfoRow
						label={t('baseOrderDetails.orderedQuantity', 'Ordered quantity:')}
						value={
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									{target !== OrderTarget.admin &&
									target !== OrderTarget.customer
										? selectedOrder?.trucks?.[0].payload
										: selectedOrder?.quantity}{' '}
									<T keyName="basicDetails.tons">tons</T>
								</p>
							</SypacText>
						}
					/>

					{target !== OrderTarget.transporter &&
					selectedOrder?.trucks?.every(
						(item) => item.loadedQuantity !== undefined,
					) ? (
						simpleOrder ? (
							<InfoRow
								label={t('baseOrderDetails.loadedQuantity', 'Loaded quantity:')}
								value={
									<div className="flex gap-3 items-center whitespace-nowrap">
										<SypacText variant="body-regular-medium">
											<p className="font-bold">
												{(() => {
													const total =
														selectedOrder?.trucks?.reduce(
															(sum, item) => sum + item.loadedQuantity!,
															0,
														) || 0;
													return total % 1 === 0
														? total.toString()
														: total.toFixed(2);
												})()}{' '}
												tons
											</p>
										</SypacText>
										<span className="flex items-center h-[22px] bg-gray-10 rounded px-1">
											<SypacText variant="body-regular-medium">
												<p className="text-xs leading-none">
													{variationPercent(
														target === OrderTarget.producer
															? selectedOrder?.trucks?.[0]?.loadedQuantity!
															: selectedOrder?.trucks?.reduce(
																	(sum, item) => sum + item.loadedQuantity!,
																	0,
															  ),
														target === OrderTarget.producer
															? selectedOrder?.trucks?.[0]?.payload
															: selectedOrder?.quantity,
													)}
													%
												</p>
											</SypacText>
										</span>
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-40 whitespace-normal">
												<T keyName="baseOrderDetails.quantityDifference">
													Quantity difference
												</T>
											</p>
										</SypacText>
									</div>
								}
							/>
						) : selectedOrder?.status === 'paid' ||
						  selectedOrder?.status === 'delivered' ? (
							selectedOrder?.trucks?.map((orderTruck, index) => (
								<div
									className={`flex flex-col gap-3 ${index === 0 ? 'mt-6' : ''}`}
									key={orderTruck?.id}
								>
									<div className="flex gap-4">
										<div className="flex items-center w-fit h-[22px] bg-gray-10 rounded-md px-[14px]">
											<SypacText variant="body-regular-medium">
												<p className="text-sm text-gray-90">
													<T keyName="requestedStep.payloadTruck">Truck</T>{' '}
													{index + 1}
												</p>
											</SypacText>
										</div>
										<SypacText variant="body-regular-medium">
											<p className="text-gray-60">
												{orderTruck?.driver?.name ||
													orderTruck?.driver?.licensePlates}
											</p>
										</SypacText>
									</div>

									<InfoRow
										label={t(
											'baseOrderDetails.loadedQuantity',
											'Loaded quantity:',
										)}
										value={
											<div className="flex gap-3 items-center whitespace-nowrap">
												<SypacText variant="body-regular-medium">
													<p className="font-bold">
														{orderTruck?.loadedQuantity
															? Math.round(orderTruck.loadedQuantity * 2) / 2
															: 0}{' '}
														tons
													</p>
												</SypacText>
												<span className="flex items-center h-[22px] bg-gray-10 rounded px-1">
													<SypacText variant="body-regular-medium">
														<p className="text-xs leading-none">
															{variationPercent(
																orderTruck?.loadedQuantity!,
																orderTruck.payload!,
															)}
															%
														</p>
													</SypacText>
												</span>
												<SypacText variant="body-regular-medium">
													<p className="text-sm text-gray-40 whitespace-normal">
														<T keyName="baseOrderDetails.quantityDifference">
															Quantity difference
														</T>
													</p>
												</SypacText>
											</div>
										}
									/>
								</div>
							))
						) : null
					) : null}

					{target === OrderTarget.admin ? (
						<InfoRow
							label={t(
								'baseOrderDetails.productCostTotal',
								'Product cost (total):',
							)}
							value={euro.format(
								selectedOrder?.productPriceForClientWithVat || 0,
							)}
						/>
					) : null}

					{target === OrderTarget.admin && deliveryIncluded ? (
						<InfoRow
							label={t(
								'baseOrderDetails.transportationCost',
								'Transportation cost:',
							)}
							value={euro.format(shippingCost || 0)}
						/>
					) : null}

					{target === OrderTarget.transporter ? (
						<>
							{selectedOrder?.pickupLocation &&
							selectedOrder?.deliveryLocation ? (
								<InfoRow
									label={t('baseOrderDetails.distance', 'Distance:')}
									value={`${selectedOrder?.distance} km`}
									className="mt-6"
								/>
							) : null}
							<InfoRow
								label={t(
									'baseOrderDetails.startTripPrice',
									'Start trip price:',
								)}
								value={euro.format(selectedOrder?.transporterStartPrice ?? 0)}
							/>
							<InfoRow
								label={t(
									'baseOrderDetails.pricePerKm',
									'Price per km (excl. VAT):',
								)}
								value={euro.format(selectedOrder?.transporterPricePerKm ?? 0)}
							/>
							<InfoRow
								label={t(
									'baseOrderDetails.tripPrice',
									'Trip price (exc. VAT):',
								)}
								value={euro.format(priceNoVat ?? 0)}
							/>
						</>
					) : null}

					{target !== OrderTarget.transporter ? (
						<InfoRow
							label={t('baseOrderDetails.paymentTerms', 'Payment terms:')}
							value={
								<div className="flex gap-3 items-center whitespace-nowrap">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{selectedOrder?.paymentMethod === PaymentMethod.bank ? (
												<T keyName="baseOrderDetails.bankWire">Bank Wire</T>
											) : (
												<T keyName="baseOrderDetails.card">Card</T>
											)}
										</p>
									</SypacText>
									<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{selectedOrder?.paymentTerm}{' '}
											<T keyName="baseOrderDetails.paymentDays">Days</T>
										</p>
									</SypacText>
								</div>
							}
							className="mt-6"
						/>
					) : null}

					<div
						className={`grid grid-cols-[250px,auto] mb-3 items-start ${
							target === OrderTarget.transporter ? 'mt-6' : ''
						}`}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								<T keyName="baseOrderDetails.totalPriceExclVat">
									Total price (excl. VAT):
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{euro.format(
									(target === OrderTarget.producer
										? selectedOrder?.priceForProducer
										: priceNoVat) || 0,
								)}
							</p>
						</SypacText>
					</div>
					<div className="grid grid-cols-[250px,auto] mb-3 items-start">
						<SypacText variant="body-regular-medium">
							<p className="text-lg text-gray-80">
								<T
									keyName="baseOrderDetails.totalPriceInclVat"
									params={{
										vat: `${
											target !== OrderTarget.transporter
												? orderVat
												: selectedOrder?.transportVat
										}`,
									}}
								>
									Total price (incl. VAT)
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p className="text-lg font-bold text-gray-80">
								{euro.format(
									(target === OrderTarget.producer
										? selectedOrder?.priceForProducerWithVat
										: priceWithVat) || 0,
								)}
							</p>
						</SypacText>
					</div>

					{[
						OrderStatusesEnum.DELIVERED,
						OrderStatusesEnum.PAID,
						OrderStatusesEnum.PENDING_PAYMENT,
					].includes(selectedOrder?.status!) ? (
						isLoading || orderInvoice === undefined ? (
							<div className="flex w-full justify-center items-center my-6">
								<div className="mr-[18px]">
									<BouncingLoader />
								</div>
							</div>
						) : (
							<>
								<InfoRow
									label={t('paymentTerms.invoiceStatus', 'Invoice status:')}
									className="mt-4"
									isBadge={true}
									badgeContent={
										<div className="flex items-center gap-3">
											{calculateRemainingDays(orderInvoice?.dueDate!) > 0 ? (
												<>
													<StatusBadge
														status={orderInvoice?.status!}
														isInvoiceStatus={true}
													/>
													{orderInvoice?.status ===
													PaymentStatusEnum.PENDING ? (
														<>
															<span className="flex items-center h-[22px] bg-gray-10 rounded px-1">
																<SypacText variant="body-regular-medium">
																	<p className="text-xs truncate">
																		{calculateRemainingDays(
																			orderInvoice.dueDate,
																		)}{' '}
																		<T keyName="baseOrderDetails.paymentDays">
																			Days
																		</T>
																	</p>
																</SypacText>
															</span>
															<SypacText variant="body-regular-medium">
																<p className="text-sm text-gray-40 truncate">
																	<T keyName="paymentTerms.remainingToPay">
																		Remaining to pay
																	</T>
																</p>
															</SypacText>
														</>
													) : null}
												</>
											) : (
												<>
													<SypacBadge
														color={PaymentStatusColor['overdue']}
														size="large"
														className="h-[24px]"
													>
														<div className="flex gap-1 items-center text-red w-fit justify-center cursor-auto">
															<SypacIcon
																iconName="Danger Filled"
																size="custom"
																width="12px"
																height="11px"
															/>
															<SypacText variant="overline-regular-large">
																<p className="text-white">
																	<T keyName="paymentStatus.overdue">Overdue</T>
																</p>
															</SypacText>
														</div>
													</SypacBadge>
													<span className="flex items-center h-[22px] bg-gray-10 rounded px-1">
														<SypacText variant="body-regular-medium">
															<p className="text-xs truncate">
																{Math.abs(
																	calculateRemainingDays(
																		orderInvoice?.dueDate!,
																	),
																)}{' '}
																<T keyName="baseOrderDetails.paymentDays">
																	Days
																</T>
															</p>
														</SypacText>
													</span>
													<SypacText variant="body-regular-medium">
														<p className="text-sm text-gray-40 truncate">
															<T keyName="paymentTerms.sinceOverdue">
																Since overdue
															</T>
														</p>
													</SypacText>
												</>
											)}
										</div>
									}
								/>

								<InfoRow
									label={t('paymentTerms.issued', 'Issued:')}
									value={
										<div className="flex items-center gap-3 ml-[5px]">
											<span className="p-0 m-0 flex scale-[1.1]">
												<Calendar />
											</span>
											<SypacText variant="body-regular-medium">
												<p className="text-gray-80">
													{dayjs(orderInvoice?.createdAt).format(
														'DD MMM. YYYY',
													)}
												</p>
											</SypacText>
										</div>
									}
								/>

								<div className="flex gap-5 mb-5">
									<SypacButton
										variant="secondary"
										size="large"
										className="w-full"
									>
										<button
											className="w-full h-[42px] transition"
											onClick={() =>
												actionClick(PaymentTypeAction.download, orderInvoice!)
											}
										>
											<span className="p-0 m-0 flex scale-[1.1]">
												<Invoice />
											</span>
											<SypacText variant="body-normal-medium">
												<p>
													<T keyName="paymentsWithdrawHistory.downloadInvoice">
														Download invoice
													</T>
												</p>
											</SypacText>
										</button>
									</SypacButton>

									<SypacButton
										variant="secondary"
										size="large"
										className="w-full"
									>
										<button
											className="w-full h-[42px] transition"
											onClick={() =>
												actionClick(
													PaymentTypeAction.view_invoice,
													orderInvoice!,
												)
											}
										>
											<SypacIcon
												icon-name="Eye"
												className="text-gray"
												size="custom"
												width="32px"
												height="32px"
											/>
											<SypacText variant="body-normal-medium">
												<p className="-ml-[6px]">
													<T keyName="paymentsWithdrawHistory.viewInvoice">
														View invoice
													</T>
												</p>
											</SypacText>
										</button>
									</SypacButton>
								</div>
							</>
						)
					) : null}
				</div>
			</ExpandedBox>

			{openView ? (
				<DocumentsViewer
					url={invoiceUrl!}
					isPdf={true}
					isOpen={openView}
					onClose={() => setShowView(false)}
				/>
			) : null}

			<Tooltip
				place="top"
				id="base-order-details"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
					whiteSpace: 'normal',
					maxWidth: '248px',
				}}
			/>
		</>
	);
};

export default BaseOrderDetails;
