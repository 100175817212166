/* eslint-disable eqeqeq */
import React, { FC, useEffect, useState } from 'react';
import { SypacIcon, SypacText } from '@sypac/component-library-react';

import CompanyDetails from './components/CompanyDetails/CompanyDetails';
import UserDetails from './components/UserDetails/UserDetails';
import BankDetails from './components/BankDetails/BankDetails';
import CompanyDocuments from './components/CompanyDocuments/CompanyDocuments';
import { T } from '@tolgee/react';
import CompanyStepper from '../CompanyStepper/CompanyStepper';

interface CompanyVerificationProps {
	isOpen: boolean;
	companyId: number;
	closeModal: () => void;
}

const CompanyVerification: FC<CompanyVerificationProps> = (props) => {
	let { isOpen, closeModal, companyId } = props;

	const [currentStep, setCurrentStep] = useState(0);

	useEffect(() => {
		setCurrentStep(0);
	}, [companyId]);

	return (
		<>
			{isOpen ? (
				<div className="absolute min-h-full top-0 right-0 w-[716px] bg-white-100 shadow-order-details z-[100] h-screen overflow-y-auto scroll-smooth">
					<div className="h-full overflow-x-hidden overflow-y-scroll">
						<div className="flex flex-row justify-between items-center p-10 pb-7">
							<SypacText variant="body-regular-large">
								<p className="text-gray-90 -tracking-[0.02em]">
									<T keyName="companyVerification.companyVerification">
										Company verification
									</T>
								</p>
							</SypacText>
							<SypacIcon
								iconName="Close Square"
								className="text-gray-10 ml-6 cursor-pointer"
								size="custom"
								width="32px"
								height="32px"
								onClick={closeModal}
							/>
						</div>
						<div className="flex items-center pl-10 bg-gray-1 w-[2450px] h-[90px]">
							<div
								className={`relative 
						${
							currentStep == 0
								? 'left-0'
								: currentStep == 1
								? '-left-[275px]'
								: currentStep == 2
								? '-left-[585px]'
								: currentStep == 3
								? '-left-[825px]'
								: null
						}
						`}
							>
								<CompanyStepper
									current={currentStep}
									minWidth={80}
									target="company"
								/>
							</div>
						</div>
						<div className="relative">
							{currentStep == 0 ? (
								<CompanyDetails
									companyId={companyId}
									onBack={closeModal}
									onSubmit={() => setCurrentStep((prevState) => prevState + 1)}
								/>
							) : currentStep == 1 ? (
								<CompanyDocuments
									companyId={companyId}
									onBack={() => setCurrentStep((prevState) => prevState - 1)}
									onSubmit={() => setCurrentStep((prevState) => prevState + 1)}
								/>
							) : currentStep == 2 ? (
								<UserDetails
									companyId={companyId}
									role="admin"
									onBack={() => setCurrentStep((prevState) => prevState - 1)}
									onSubmit={() => setCurrentStep((prevState) => prevState + 1)}
								/>
							) : currentStep == 3 ? (
								<BankDetails
									companyId={companyId}
									onBack={() => setCurrentStep((prevState) => prevState - 1)}
									onClose={closeModal}
								/>
							) : null}
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default CompanyVerification;
