import { SypacTabs, SypacText } from '@sypac/component-library-react';
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { MutatingDots } from 'react-loader-spinner';
import { AvatarItem } from '../../../components/AvatarDropDown/AvatarDropDown.interface';
import {
	NotificationCategory,
	NotificationInterface,
	OrderInterface,
} from '../../../components/OrdersTable/interfaces/Order.interface';
import { OrdersTable } from '../../../components/OrdersTable/OrdersTable';
import { UsersInterface } from '../../../interfaces/Users.interface';
import { LIMIT } from '../../../constants';
import { AuthContext } from '../../../context/context';
import { OrderContext } from '../../../context/OrderContext/order.context';
import { OrdersService } from '../../../services/orders.services';
import OrderDetails from '../../Producer/OrderDetails';
import Pagination from '../../../components/Pagination/Pagination';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import Emitter, { EventType } from '../../../services/events';
import { T, useTranslate } from '@tolgee/react';
import { useLocation } from 'react-router-dom';
import { TabButtonNotification } from '../../../components/TabButtonNotification/TabButtonNotification';
import { NotificationsService } from '../../../services/notifications.services';

const OrdersList: React.FC = () => {
	const { t } = useTranslate();
	const [orders, setOrders] = useState<OrderInterface[]>([]);
	const [isOpen, setOpenDetails] = useState<boolean>(false);
	const [selectedOrder, setOrder] = useState<OrderInterface | undefined>(
		undefined,
	);
	const { state } = useLocation();
	const [page, setPage] = useState<number>(0);
	const [count, setCount] = useState<number>(0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setUsers] = useState<AvatarItem[]>([]);
	const [assigneeIds] = useState<string[]>([]);
	const [statuses, setStatuses] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedTab, setSelectedTab] = useState('all');
	const [notifications, setNotifications] = useState<any>({});
	const { user } = useContext(AuthContext);
	const {
		setOrderId,
		search: searchLocal,
		additional,
	} = useContext(OrderContext);
	const tableRef = useRef<HTMLDivElement>(null);
	const detailsRef = useRef<HTMLDivElement>(null);

	const getNotifications = useCallback(async () => {
		if (!user?.uid) return;

		try {
			const { data } = await NotificationsService.getCount({
				recipientId: user.uid,
				limit: 50,
			});
			const notifies = Array.from(
				new Map(data.items.map((item) => [item.groupId, item])).values(),
			);

			if (notifies) {
				const statuses: any = {};

				for (const notify of notifies) {
					const orderId = parseInt(notify.groupId);
					let data: OrderInterface | undefined;
					const respOrder = await OrdersService.getOrder(
						orderId,
						OrderTarget.producer,
					);
					data = respOrder.data;
					statuses[data.status] = (statuses[data.status] || 0) + 1;
				}

				setNotifications(statuses);
			}
		} catch (e) {}
	}, [user?.uid]);

	useEffect(() => {
		Emitter.on(EventType.NOTIFICATION_COUNT, () => {
			getNotifications();
		});
		Emitter.on(EventType.NOTIFICATION_COUNT_DECREASE, (count) => {
			setNotifications((prev: any) => (prev - count >= 0 ? prev - count : 0));
			getNotifications();
		});

		return () => {
			Emitter.off(EventType.NOTIFICATION_COUNT);
			Emitter.off(EventType.NOTIFICATION_COUNT_DECREASE);
		};
	}, [getNotifications]);

	useEffect(() => {
		getNotifications();
	}, [getNotifications]);

	const getOrdersCallback = useCallback(async () => {
		try {
			setIsLoading(true);
			const query = {
				assigneeIds,
				statuses,
				searchQuery: searchLocal,
				limit: LIMIT,
				offset: page * LIMIT,
			};
			const { data } = await OrdersService.getOrders(query, 'producer');
			setOrders((data.items as unknown as OrderInterface[]) || []);
			setCount(data.count || 0);
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	}, [assigneeIds, page, searchLocal, statuses]);

	useEffect(() => {
		getOrdersCallback();
	}, [getOrdersCallback]);

	const rowClick = useCallback(
		async (order: Partial<OrderInterface>) => {
			try {
				const { data } = await OrdersService.getOrder(
					order.id!,
					OrderTarget.producer,
				);
				setOrderId(order.id);
				setOrder(data as unknown as OrderInterface);
				setOpenDetails(true);
			} catch (e) {
				console.log(e);
			}
		},
		[setOrderId],
	);

	useEffect(() => {
		if (state && state.orderId) {
			rowClick({ id: parseInt(state.orderId, 10) });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	useEffect(() => {
		if (additional && additional.order) {
			rowClick(additional.order);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [additional]);

	useEffect(() => {
		Emitter.on(EventType.ORDER_REFRESH, (items: NotificationInterface[]) => {
			const opened = items.some(
				(r) => r.groupId === selectedOrder?.id.toString(),
			);
			if (opened && selectedOrder?.id) {
				rowClick(selectedOrder);
			}
		});
		return () => {
			Emitter.off(EventType.ORDER_REFRESH);
		};
	}, [rowClick, selectedOrder]);

	useEffect(() => {
		if (user) {
			const me = user as UsersInterface;
			setUsers([
				{ id: me.uid, fullName: me.name || me.email, avatarUrl: me.avatarUrl },
			]);
		}
	}, [user]);

	const closeModal = () => {
		setOrder(undefined);
		setOpenDetails(false);
	};

	const setCurrentTab = (statuses: string[]) => {
		setPage(0);
		setStatuses(statuses);
	};

	const getOrderCallback = useCallback(
		async (notify: NotificationInterface) => {
			try {
				const orderId = parseInt(notify.groupId);
				const { data } = await OrdersService.getOrder(
					orderId,
					OrderTarget.producer,
				);
				if (orderId === selectedOrder?.id) {
					setOrder({
						...data,
						notificationCount: 0,
					} as unknown as OrderInterface);
				}
				let newOrders = [];
				if (notify.category === NotificationCategory.NEW_ORDER) {
					const filterData = orders.filter((r) => r.id !== data.id);
					newOrders = [{ ...data, notificationCount: 1 }, ...filterData];
				} else {
					newOrders = orders.map((order) => {
						if (order.id === orderId) {
							const notificationFilter = data.notifications.items.filter(
								(r) => !r.readAt,
							);
							return {
								...order,
								...data,
								notificationCount:
									orderId === selectedOrder?.id ? 0 : notificationFilter.length,
							};
						}
						return order;
					});
				}
				setOrders(newOrders as unknown as OrderInterface[]);
			} catch (e) {
				console.log(e);
			} finally {
				setIsLoading(false);
			}
		},
		[orders, selectedOrder?.id],
	);

	useEffect(() => {
		Emitter.on(EventType.ORDER_LIST_REFRESH, (notify: any) => {
			if (notify && notify.groupId) {
				getOrderCallback(notify);
			}
		});
		return () => {
			Emitter.off(EventType.ORDER_LIST_REFRESH);
		};
	}, [getOrderCallback]);

	const decreaseNotification = useCallback(
		(orderId: number) => {
			const newOrders = orders.map((order) => {
				if (order.id === orderId) {
					return {
						...order,
						notificationCount: 0,
					};
				}
				return order;
			});
			setOrders(newOrders as unknown as OrderInterface[]);
		},
		[orders],
	);

	useEffect(() => {
		Emitter.on(
			EventType.ORDER_NOTIFICATION_COUNT_DECREASE,
			(orderId: number) => {
				decreaseNotification(orderId);
			},
		);

		return () => {
			Emitter.off(EventType.ORDER_NOTIFICATION_COUNT_DECREASE);
		};
	}, [decreaseNotification]);

	const clickOutsideDetails = useCallback((event: MouseEvent) => {
		const toastContainer = document.querySelector('.Toastify');

		if (
			!detailsRef.current?.contains(event.target as Node) &&
			!tableRef.current?.contains(event.target as Node) &&
			!additional.orderModalRef.current?.contains(event.target as Node) &&
			!(toastContainer && toastContainer.contains(event.target as Node))
		) {
			closeModal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', clickOutsideDetails);
		return () => {
			document.removeEventListener('mousedown', clickOutsideDetails);
		};
	}, [clickOutsideDetails]);

	const sortedOrders = orders.sort((a, b) => {
		if (a.notificationCount! > 0 && b.notificationCount! > 0) {
			return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
		}

		if (a.notificationCount! > 0) return -1;
		if (b.notificationCount! > 0) return 1;

		return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
	});

	return (
		<>
			<div className="flex flex-col gap-5 w-[calc(100vw-77px)] xl-2xl:w-[calc(100vw-94px)] h-full relative">
				<div className="hidden xl:flex flex-col gap-4">
					<div className="flex flex-col gap-4 h-[78px] ml-5">
						<SypacText variant="body-regular-large">
							<p className="text-gray-80 text-3xl tracking-tighter">
								<T keyName="ordersDashboard.orderListDashboard">
									Order list dashboard
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-large">
							<p className="text-gray-40 text-base tracking-tighter">
								<T keyName="ordersDashboard.trackYourOrdersFromProcessingDelivery">
									Track your orders, from processing to delivery.
								</T>
							</p>
						</SypacText>
					</div>
					<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />
				</div>

				<div className="flex items-center gap-6 2xl:gap-12 max-w-[1047px] ml-5">
					<SypacTabs className="w-fit 2xl:w-[788px]">
						<TabButtonNotification
							label={t('orders.all', 'All')}
							buttonId="all"
							onClick={() => setCurrentTab([])}
							isSelected={selectedTab === 'all'}
							handleSelectedTab={() => setSelectedTab('all')}
							notification={Object.values(notifications).reduce<number>(
								(sum, count) => sum + (count as number),
								0,
							)}
						/>
						<TabButtonNotification
							label={t('ordersList.toDo', 'To do')}
							buttonId="to_do"
							onClick={() => setCurrentTab(['to_do'])}
							isSelected={selectedTab === 'to_do'}
							handleSelectedTab={() => setSelectedTab('to_do')}
							notification={notifications?.['to_do']}
						/>
						<TabButtonNotification
							label={t('ordersList.progress', 'In progress')}
							buttonId="progress"
							onClick={() =>
								setCurrentTab([
									'in_progress',
									'loading',
									'loaded',
									'confirm_pick_up',
								])
							}
							isSelected={selectedTab === 'progress'}
							handleSelectedTab={() => setSelectedTab('progress')}
							notification={[
								'in_progress',
								'loading',
								'loaded',
								'confirm_pick_up',
							].reduce(
								(total, status) => total + (notifications?.[status] || 0),
								0,
							)}
						/>
						<TabButtonNotification
							label={t('ordersList.delivered', 'Delivered')}
							buttonId="delivered"
							onClick={() => setCurrentTab(['delivered', 'pending_payment'])}
							isSelected={selectedTab === 'delivered'}
							handleSelectedTab={() => setSelectedTab('delivered')}
							notification={['delivered', 'pending_payment'].reduce(
								(total, status) => total + (notifications?.[status] || 0),
								0,
							)}
						/>
						<TabButtonNotification
							label={t('ordersList.completed', 'Completed')}
							buttonId="completed"
							onClick={() => setCurrentTab(['paid', 'completed'])}
							isSelected={selectedTab === 'completed'}
							handleSelectedTab={() => setSelectedTab('completed')}
							notification={['paid', 'completed'].reduce(
								(total, status) => total + (notifications?.[status] || 0),
								0,
							)}
						/>
					</SypacTabs>
					{/* <div className="flex-none">
						<div className="flex flex-row justify-end">
							<AvatarDropDown items={users} onChange={changeUser} />
						</div>
					</div> */}
				</div>

				{isLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
					</div>
				) : (
					<div className="h-full relative ml-5 mr-7.5 border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
						<div
							className="w-full h-[calc(100%-56px)] overflow-y-auto scroll-smooth pr-[5px]"
							ref={tableRef}
						>
							<OrdersTable
								rows={sortedOrders}
								target={OrderTarget.producer}
								rowClick={rowClick}
								search={searchLocal}
								currentTab={selectedTab}
							/>
						</div>
						<div className="w-full absolute bottom-0 border border-solid border-gray-10 border-l-0 border-r-0 border-b-0 shadow-pagination">
							<div className="flex justify-between items-center h-[56px] px-4">
								<Pagination
									count={count}
									page={page}
									onClick={(item: any) => setPage(item)}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			<div ref={detailsRef}>
				<OrderDetails
					isOpen={!!(isOpen && selectedOrder?.id)}
					selectedOrder={selectedOrder}
				/>
			</div>
		</>
	);
};

export default OrdersList;
