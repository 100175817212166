import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslate } from '@tolgee/react';
import ProductTabs from './components/ProductTabs';
import { AvatarItem } from '../../../components/AvatarDropDown/AvatarDropDown.interface';
import AvatarDropDown from '../../../components/AvatarDropDown/AvatarDropDown';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { ProductsTable } from '../../../components/ProductsTable/ProductsTable';
import { MutatingDots } from 'react-loader-spinner';
import Pagination from '../../../components/Pagination/Pagination';
import { ProductInterface } from '../../../interfaces/product.interface';
import { LIMIT } from '../../../constants';
import {
	EditProduct,
	ProductService,
} from '../../../services/product.services';
import CompanyDetails from '../ComanyDetails';
import ProductDetails from '../ProductDetails';
import { UsersService } from '../../../services/Users.service';
import ModalDeleteProduct from '../../../components/ModalDeleteProduct/ModalDeleteProduct';
import ModalStoreProduct from '../../../components/ModalStoreProduct/ModalStoreProduct';
import useDebounce from '../../../hooks/useDebounce';

const Products: React.FC = () => {
	const { t } = useTranslate();
	const [currentTab, setCurrentTab] = useState<string>('all');
	const [avatars, setAvatars] = useState<AvatarItem[]>([]);
	const [products, setProducts] = useState<ProductInterface[]>([]);
	const [selectedCompany] = useState<number | undefined>(undefined);
	const [showDetails, setShowDetails] = useState<boolean>(false);
	const [tabs, setTabs] = useState([
		{ label: 'All', color: 'white', count: '0', value: 'all' },
		{ label: 'Approved', color: 'green', count: '0', value: 'approved' },
		{ label: 'Pending approve', color: 'yellow', count: '0', value: 'pending' },
		{ label: 'Rejected', color: 'gray', count: '0', value: 'rejected' },
	]);
	const [assigneeId, setAssignee] = useState<string>();
	const [page, setPage] = useState<number>(0);
	const [searchQuery, setSearchQuery] = useState<string>();
	const [count, setCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<
		ProductInterface | undefined
	>(undefined);
	const [showDetailsProduct, setShowDetailsProduct] = useState<boolean>(false);
	const [showRejectProduct, setShowRejectProduct] = useState<boolean>(false);
	const [showOnStore, setShowOnProduct] = useState<boolean>(false);
	const refs = useRef<(HTMLDivElement | null)[]>([]);
	const search = useDebounce(searchQuery, 500);

	const getProductList = useCallback(async () => {
		setLoading(true);
		try {
			const { data } = await ProductService.getListAdmin({
				status: currentTab,
				limit: LIMIT,
				offset: page * LIMIT,
				verifiedBy: assigneeId,
				search,
			});

			setProducts(data.items);
			setCount(data.count);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	}, [assigneeId, currentTab, page, search]);

	const getUsers = useCallback(async () => {
		try {
			const users = await UsersService.getUsers({
				limit: 10,
				skip: 0,
				companyId: 'self',
			});

			const avatars: AvatarItem[] = users.data?.items
				.map((user) => {
					return {
						id: user.uid,
						fullName: user?.firstName
							? `${user?.firstName} ${user?.lastName}`
							: user?.name,
					};
				})
				.filter((r) => r.fullName);
			setAvatars(avatars);
		} catch (e) {}
	}, []);

	const getStatistics = useCallback(async () => {
		setLoading(true);
		try {
			const [all, approved, pending, rejected] = await Promise.all([
				ProductService.getListAdmin({ status: 'all', limit: 0 }),
				ProductService.getListAdmin({ status: 'approved', limit: 0 }),
				ProductService.getListAdmin({ status: 'pending', limit: 0 }),
				ProductService.getListAdmin({ status: 'rejected', limit: 0 }),
			]);

			setTabs([
				{
					label: 'All',
					color: 'white',
					count: all?.data?.count?.toString() ?? '0',
					value: 'all',
				},
				{
					label: 'Pending approve',
					color: 'yellow',
					count: pending?.data?.count?.toString() ?? '0',
					value: 'pending',
				},
				{
					label: 'Approved',
					color: 'green',
					count: approved?.data?.count?.toString() ?? '0',
					value: 'approved',
				},
				{
					label: 'Rejected',
					color: 'gray',
					count: rejected?.data?.count?.toString() ?? '0',
					value: 'rejected',
				},
			]);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getStatistics();
		getUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getProductList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, currentTab]);

	const selectTab = (tab: string) => {
		setCurrentTab(tab);
		setPage(0);
	};

	const changeAssignee = (uid: string) => {
		const assignee = assigneeId === uid ? undefined : uid;
		setAssignee(assignee);
		setPage(0);
	};

	const rowClick = (product: ProductInterface) => {
		const selectedProduct = products.find((r) => r.id === product.id);
		setSelectedProduct(selectedProduct);
		setShowDetailsProduct(true);
	};

	const openCompany = (id: number) => {
		// setSelectedCompany(id);
		// setShowDetails(true);
	};

	const approveProduct = async () => {
		try {
			if (selectedProduct?.id) {
				setLoading(true);
				await ProductService.actionProduct(selectedProduct?.id, 'approve');
				setSelectedProduct({ ...selectedProduct, status: 'approved' });
				const newProducts = products.map((p) => {
					if (p.id === selectedProduct.id) {
						return { ...p, ...selectedProduct, status: 'approved' };
					}
					return p;
				});
				setProducts(newProducts);
				getStatistics();
			}
		} catch (e) {}
	};

	const checkRejectProduct = async () => {
		setShowRejectProduct(true);
	};

	const rejectProduct = async () => {
		try {
			setShowRejectProduct(false);
			if (selectedProduct?.id) {
				setLoading(true);
				await ProductService.actionProduct(selectedProduct?.id, 'reject');
				setSelectedProduct({ ...selectedProduct, status: 'rejected' });
				const newProducts = products.map((p) => {
					if (p.id === selectedProduct.id) {
						return { ...p, ...selectedProduct, status: 'rejected' };
					}
					return p;
				});
				setProducts(newProducts);
				getStatistics();
			}
		} catch (e) {}
	};

	const editProduct = async (data: EditProduct) => {
		try {
			if (selectedProduct?.id) {
				setLoading(true);
				const resp = await ProductService.editProduct(
					selectedProduct?.id,
					data,
				);
				const updatedProduct = resp.data;
				const newProduct = {
					...selectedProduct,
					...updatedProduct,
					pricePerUnitWithProcent:
						updatedProduct.pricePerUnit +
						(updatedProduct.pricePerUnit * updatedProduct.markup || 0) / 100,
				} as unknown as ProductInterface;
				getStatistics();
				setSelectedProduct(newProduct);
				const items = products.map((r) => {
					if (r.id === selectedProduct?.id) {
						return newProduct;
					}
					return r;
				});
				setProducts(items);
			}
		} catch (e) {}
	};

	const clickOutsideDetails = useCallback((event: MouseEvent) => {
		const isOutside = refs.current.every(
			(ref) => ref && !ref.contains(event.target as Node),
		);
		if (isOutside) {
			setSelectedProduct(undefined);
			setShowDetailsProduct(false);
		}
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', clickOutsideDetails);
		return () => {
			document.removeEventListener('mousedown', clickOutsideDetails);
		};
	}, [clickOutsideDetails]);

	return (
		<>
			<div className="flex flex-col gap-8 w-[calc(100vw-77px)] xl-2xl:w-[calc(100vw-94px)] h-full">
				<div className="col-span-12 grid grid-cols-12 gap-2.5 ml-5">
					<div className="col-span-6">
						<div className="flex flex-row flex-wrap gap-7.5">
							<div className="flex flex-row max-w-[540px] min-w-[400px]">
								<ProductTabs
									data={tabs}
									callback={selectTab}
									activeTab={currentTab}
								/>
							</div>
							{avatars.length ? (
								<div className="flex">
									<AvatarDropDown items={avatars} onChange={changeAssignee} />
								</div>
							) : null}
						</div>
					</div>
					<div className="col-span-6">
						<SearchBar
							placeholder={t(
								'productsRequest.search',
								'Search order by product ID and product name',
							)}
							onChange={setSearchQuery}
							classes={'w-full'}
							showButton={false}
						/>
					</div>
				</div>
				<div className="w-full h-full">
					{loading ? (
						<div className="flex w-full h-full items-center justify-center">
							<MutatingDots
								height="100"
								width="100"
								color="#7693F4"
								secondaryColor="#494C83"
								radius="12.5"
								ariaLabel="mutating-dots-loading"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
							/>
						</div>
					) : (
						<div className="h-full relative ml-5 mr-7.5 border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
							<div
								className="w-full h-[calc(100%-56px)] overflow-y-auto scroll-smooth pr-[5px]"
								ref={(el) => (refs.current[0] = el)}
							>
								<ProductsTable
									rows={products}
									rowClick={rowClick}
									search={searchQuery}
									openCompany={openCompany}
								/>
							</div>
							<div className="w-full absolute bottom-0 border border-solid border-gray-10 border-l-0 border-r-0 border-b-0 shadow-pagination">
								<div className="flex justify-between items-center h-[56px] px-4">
									<Pagination
										showText={true}
										count={count}
										page={page}
										onClick={(item: any) => setPage(item)}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{selectedCompany !== undefined && showDetails ? (
				<CompanyDetails
					onClose={() => setShowDetails(false)}
					companyId={selectedCompany}
					onVerification={() => {
						setShowDetails(false);
					}}
				/>
			) : null}
			{selectedProduct !== undefined && showDetailsProduct ? (
				<div ref={(el) => (refs.current[1] = el)}>
					<ProductDetails
						onClose={() => setShowDetailsProduct(false)}
						product={selectedProduct}
						approveProduct={approveProduct}
						rejectProduct={checkRejectProduct}
						viewOnStore={() => setShowOnProduct(true)}
						editProduct={editProduct}
					/>
				</div>
			) : null}
			{showRejectProduct ? (
				<div ref={(el) => (refs.current[2] = el)}>
					<ModalDeleteProduct
						isOpen={showRejectProduct}
						onClose={() => setShowRejectProduct(false)}
						onSubmit={rejectProduct}
					/>
				</div>
			) : null}
			{showOnStore ? (
				<div ref={(el) => (refs.current[3] = el)}>
					<ModalStoreProduct
						isOpen={showOnStore}
						onClose={() => setShowOnProduct(false)}
					/>
				</div>
			) : null}
		</>
	);
};

export default Products;
