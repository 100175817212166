import React, { useCallback, useEffect, useState } from 'react';
import ModalAddTruck from '../../../components/ModalAddTruck/ModalAddTruck';
import {
	FleetQueryParams,
	DriverDto,
	TruckService,
	FleetBaseDto,
} from '../../../services/truck.services';
import { TruckCard } from '../../../components/TruckCard/TruckCard';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import ModalVerificationCode from '../../../components/ModalVerificationCode/ModalVerificationCode';
import Map from '../../../components/Map/Map';
import { Search } from '../../../assets/Search';
import { MapMarker, PinVariant } from '../../../components/Map/Map.interface';
import { T } from '@tolgee/react';
import Emitter, { EventType } from '../../../services/events';
import { NotificationInterface } from '../../../components/OrdersTable/interfaces/Order.interface';
import { MutatingDots } from 'react-loader-spinner';
import { PlusIcon } from '../../../assets/PlusIcon';
import { MapIcon } from '../../../assets/MapIcon';
import { StatusClock } from '../../../assets/StatusClock';
import { CheckmarkCircle } from '../../../assets/CheckmarkCircle';
import FleetBaseLocation from './components/FleetBaseLocation';
import { Geo } from '../../../components/SearchLocation/locations.interface';
import useDebounce from '../../../hooks/useDebounce';
import { GeoService } from '../../../services/geo.services';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';
import EditPenIcon from '../../../assets/EditPenIcon';

const Fleet: React.FC = () => {
	const [trucks, setTrucks] = useState<DriverDto[]>([]);
	const [showAddTruck, setShowAddTruck] = useState<boolean>(false);
	const [showVerificationCode, setShowVerificationCode] =
		useState<boolean>(false);
	const [status, setStatus] = useState<string>('');
	const [refresh, setRefresh] = useState<number>(0);
	const [markers, setMarkers] = useState<MapMarker[]>([]);
	const [editableTruck, setTruck] = useState<DriverDto>();
	const [loading, setLoading] = useState<boolean>(false);
	const [showMap, setShowMap] = useState<boolean>(false);
	const [openAddBase, setOpenAddBase] = useState<boolean>(false);
	const [searchLocation, setSearchLocation] = useState<string>('');
	const searchQuery = useDebounce(searchLocation, 500);
	const [locations, setLocations] = useState<Geo[]>([]);
	const [baseLocation, setBaseLocation] = useState<Geo | undefined>(undefined);
	const [baseRadius, setBaseRadius] = useState<number | undefined>(undefined);
	const [baseRegistryAddress, setBaseRegistryAddress] = useState<string>('');
	const [myFleetBase, setMyFleetBase] = useState<FleetBaseDto | undefined>(
		undefined,
	);

	let updateTimeout: string | number | NodeJS.Timeout | undefined;

	const getFleetDataCombined = useCallback(async () => {
		setShowMap(false);
		try {
			let query: FleetQueryParams = { limit: 20, offset: 0 };
			if (status?.length) {
				query = { ...query, status };
			}
			setMarkers([]);
			const { data: fleetData } = await TruckService.getTrucks(
				query,
				'transporter',
			);
			setTrucks(fleetData.items as unknown as DriverDto[]);

			const fleetMarkers = fleetData?.items
				?.map((r) => ({
					latitude: r?.lastLocation?.coordinates[1]!,
					longitude: r?.lastLocation?.coordinates[0]!,
					variant: PinVariant.truck,
					truck: r,
				}))
				.filter((r) => r.latitude && r.longitude);

			let fleetBaseMarker = null;
			let myFleetBaseData = null;
			try {
				const { data: fleetBaseData } = await TruckService.getMyFleetBase();
				const { data: locationDetails } = await GeoService.getLocations({
					phrase: fleetBaseData?.address,
					maxResults: '1',
				});

				fleetBaseMarker = {
					latitude: fleetBaseData?.location.coordinates[1],
					longitude: fleetBaseData?.location.coordinates[0],
					variant: PinVariant.fleet_base,
					fleetBase: {
						address: fleetBaseData?.address,
						countryCode: locationDetails?.[0].countryCode,
					},
					radius: {
						value: fleetBaseData?.radiusDistance,
						isAdjustable: false,
					},
				};
				myFleetBaseData = fleetBaseData;
			} catch (e) {}

			const combinedMarkers = [
				...(fleetMarkers || []),
				...(fleetBaseMarker ? [fleetBaseMarker] : []),
			];
			setMarkers(combinedMarkers);
			setShowMap(true);
			if (myFleetBaseData) {
				setMyFleetBase(myFleetBaseData);
			}
		} catch (e) {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, refresh]);

	useEffect(() => {
		getFleetDataCombined().then(() => {});
	}, [getFleetDataCombined, status]);

	const handleAddTruckModal = () => {
		setShowAddTruck(true);
	};

	const closeTruckModal = () => {
		setShowAddTruck(false);
	};

	const closeVerificationCodeModal = () => {
		setShowVerificationCode(false);
		setTruck(undefined);
		setRefresh(refresh + 1);
	};

	const setCurrentTab = (status: string) => {
		if (status === 'all') {
			setStatus('');
		} else {
			setStatus(status);
		}
	};

	const editTruck = (truck: DriverDto) => {
		setTruck(truck);
		setShowAddTruck(true);
	};

	const showTruck = async (truck: DriverDto) => {
		try {
			setLoading(true);
			const result = await TruckService.generateQr(truck.id);
			setTruck(result.data);
			setShowVerificationCode(true);
		} finally {
			setLoading(false);
		}
	};

	const onSuccess = (truck?: DriverDto) => {
		if (!editableTruck) {
			showTruck(truck!);
		} else {
			setRefresh(refresh + 1);
			setTruck(undefined);
		}
		closeTruckModal();
	};

	const setUpdateLocation = useCallback(
		(notify: NotificationInterface) => {
			if (!markers.length) {
				return;
			}
			const lastMarkers = JSON.parse(JSON.stringify(markers)) as MapMarker[];
			const newMarkers = lastMarkers
				.map((r) => {
					if (r.truck?.uid === notify.content.driverId) {
						return {
							...r,
							latitude: notify.content.lat
								? parseFloat(notify.content.lat)
								: r.latitude,
							longitude: notify.content.long
								? parseFloat(notify.content.long)
								: r.longitude,
						};
					}
					return r;
				})
				.filter((r) => r.latitude && r.longitude);
			setMarkers(newMarkers);
		},
		[markers],
	);

	const checkUpdateLocation = useCallback((notify: NotificationInterface) => {
		clearTimeout(updateTimeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		updateTimeout = setTimeout(() => {
			setUpdateLocation(notify);
		}, 2000);
		return () => {
			clearTimeout(updateTimeout);
		};
	}, []);

	useEffect(() => {
		Emitter.on(
			EventType.TRUCK_UPDATE_LOCATION,
			(notify: NotificationInterface) => {
				if (notify.groupId) {
					checkUpdateLocation(notify);
				}
			},
		);
		return () => {
			Emitter.off(EventType.TRUCK_UPDATE_LOCATION);
		};
	}, [checkUpdateLocation]);

	const handleClose = (shouldRefresh = false) => {
		setOpenAddBase(false);
		if (shouldRefresh) {
			setRefresh(refresh + 1);
		}
	};

	const getSearchLocation = useCallback(() => {
		if (searchQuery.length) {
			GeoService.getLocations({ phrase: searchQuery, maxResults: '10' })
				.then(({ data }) => {
					setLocations(data);
				})
				.catch(console.error);
		} else {
			setLocations([]);
		}
	}, [searchQuery]);

	useEffect(() => {
		getSearchLocation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const getBaseCoords = useCallback(
		async (locationId: string) => {
			if (locationId) {
				try {
					const { data } = await GeoService.getLocationDetails({
						locationid: locationId,
					});
					const newMarker = {
						latitude: data.Latitude,
						longitude: data.Longitude,
						variant: PinVariant.fleet_base,
						fleetBase: {
							address: data.address?.label,
							countryCode: data.address?.countryCode,
						},
						radius: {
							value: baseRadius,
							isAdjustable: true,
						},
					};

					setMarkers((prevMarkers) => {
						const filteredMarkers = prevMarkers.filter(
							(marker) => marker.variant !== PinVariant.fleet_base,
						);
						return [...filteredMarkers, newMarker];
					});
				} catch (error) {
					return toastVariant(
						`Something went wrong. ${error?.toString()!}`,
						true,
					);
				}
			}
		},
		[baseRadius],
	);

	useEffect(() => {
		const createFleetBaseMarker = () => {
			if (myFleetBase?.location) {
				const fleetBaseMarker = {
					latitude: myFleetBase.location.coordinates[1],
					longitude: myFleetBase.location.coordinates[0],
					variant: PinVariant.fleet_base,
					fleetBase: {
						address: myFleetBase.address,
						countryCode: myFleetBase.countryCode,
					},
					radius: {
						value: myFleetBase.radiusDistance,
						isAdjustable: false,
					},
				};
				setMarkers((prevMarkers) => {
					const filteredMarkers = prevMarkers.filter(
						(marker) => marker.variant !== PinVariant.fleet_base,
					);
					return [...filteredMarkers, fleetBaseMarker];
				});
			}
		};

		if (baseLocation?.locationId) {
			getBaseCoords(baseLocation.locationId).then(() => {});
		} else if (baseRegistryAddress) {
			GeoService.getLocations({ phrase: baseRegistryAddress, maxResults: '1' })
				.then(({ data }) => {
					getBaseCoords(data[0].locationId!).then(() => {});
				})
				.catch();
		} else {
			createFleetBaseMarker();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseLocation, baseRegistryAddress, baseRadius]);

	return (
		<div className="flex w-[calc(100vw-77px)] xl-2xl:w-[calc(100vw-94px)] h-full">
			{loading ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[1000]">
					<MutatingDots
						height="100"
						width="100"
						color="#7693F4"
						secondaryColor="#494C83"
						radius="12.5"
						ariaLabel="mutating-dots-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			) : null}

			{openAddBase ? (
				<FleetBaseLocation
					locations={locations}
					onClose={handleClose}
					onChange={setSearchLocation}
					setBaseLocation={setBaseLocation}
					setBaseRadius={setBaseRadius}
					baseRadius={baseRadius}
					setBaseRegistryAddress={setBaseRegistryAddress}
					baseLocation={
						markers.filter(
							(marker) => marker.variant === PinVariant.fleet_base,
						)[0]
					}
				/>
			) : (
				<div className="flex flex-col gap-5 w-[579px]">
					<div className="flex flex-col gap-3 px-5">
						<div className="flex items-center justify-between">
							<SypacText variant="heading-4">
								<p className="text-gray-90">
									<T keyName="fleet.myFleet">My fleet</T>
								</p>
							</SypacText>

							<SypacButton variant="subTitle">
								<button
									type="button"
									className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-mountain-meadow rounded-lg transition hover:bg-mountain-meadow/80"
									onClick={handleAddTruckModal}
								>
									<PlusIcon color="white" />
								</button>
							</SypacButton>
						</div>

						{myFleetBase ? (
							<div className="flex justify-between">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-40 w-[422px] truncate">
										{myFleetBase.address}
									</p>
								</SypacText>

								<SypacButton variant="secondary" size="small">
									<button
										type="button"
										className="w-full border-none bg-transparent p-0 group"
										onClick={() => setOpenAddBase(true)}
									>
										<div className="flex items-center gap-3">
											<EditPenIcon hoverEffect={true} />
											<SypacText variant="body-normal-medium">
												<p className="text-gray-40 transition group-hover:text-gray-80">
													<T keyName="fleet.manage">Manage</T>
												</p>
											</SypacText>
										</div>
									</button>
								</SypacButton>
							</div>
						) : (
							<div className="flex items-center gap-2">
								<span className="-ml-[2px] mr-[2px] scale-[1.2]">
									<MapIcon />
								</span>
								<SypacButton variant="secondary" size="small">
									<button
										type="button"
										className="w-full border-none bg-transparent p-0 group"
										onClick={() => setOpenAddBase(true)}
									>
										<SypacText variant="body-normal-medium">
											<p className="text-cornflower-blue transition group-hover:text-gray-80">
												<T keyName="fleet.addFleetBase">Add fleet base</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>
						)}
					</div>

					{/* <div className="flex items-center gap-3">
					<div className="flex gap-1">
						<SypacText variant="body-regular-small">
							<p className="text-gray-40">
								<T keyName="fleet.today">Today:</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-small">
							<p className="text-gray-100">{dayjs().format('DD dddd, YYYY')}</p>
						</SypacText>
					</div>
					<div className="h-1 w-1 rounded-full bg-gray-40" />
					<div className="flex gap-1">
						<SypacText variant="body-regular-small">
							<p className="text-gray-40">
								<T keyName="fleet.time">Time:</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-small">
							<p className="text-gray-100">
								<Clock />
							</p>
						</SypacText>
					</div>
				</div> */}

					<div className="border-0 border-solid border-t-[1px] border-gray-10" />
					<div className="flex gap-[4px] px-5">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
									status === ''
										? 'bg-primary-violet text-white-100'
										: 'hover:bg-gray-10 bg-alabaster text-gray-80'
								}`}
								onClick={() => setCurrentTab('all')}
							>
								<SypacIcon
									iconName="Document Text"
									size="custom"
									width="24px"
									height="24px"
									className="mb-[2px]"
								/>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="fleet.all">All</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
									status === 'not_available'
										? 'bg-primary-violet text-white-100'
										: 'hover:bg-gray-10 bg-alabaster text-gray-80'
								}`}
								onClick={() => setCurrentTab('not_available')}
							>
								<span className="p-0 m-0 flex scale-[1.2] mr-[4px]">
									<StatusClock
										color={status === 'not_available' ? 'white' : undefined}
									/>
								</span>
								<SypacText variant="body-regular-medium" className="mt-[4px]">
									<p>
										<T keyName="fleet.inProgress">In progress</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group ${
									status === 'available'
										? 'bg-primary-violet text-white-100'
										: 'hover:bg-gray-10 bg-alabaster text-gray-80'
								}`}
								onClick={() => setCurrentTab('available')}
							>
								<span className="p-0 m-0 flex scale-[1.2] mr-[4px]">
									<CheckmarkCircle
										color={status !== 'available' ? '#454545' : undefined}
									/>
								</span>
								<SypacText variant="body-regular-medium" className="mt-[4px]">
									<p>
										<T keyName="fleet.available">Available</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />

					<div
						className={`flex flex-col gap-3 px-5 ${
							trucks?.length > 4 ? 'overflow-y-scroll overflow-x-hidden' : ''
						}`}
					>
						{trucks ? (
							trucks.map((truck: DriverDto) => (
								<TruckCard
									key={truck.code}
									data={truck}
									onUpdate={() => setRefresh(refresh + 1)}
									editTruck={() => editTruck(truck)}
									showTruck={() => showTruck(truck)}
								/>
							))
						) : (
							<div className="flex flex-col gap-3 items-center">
								<div className="my-16">
									<Search />
								</div>
								<SypacText variant="body-regular-large">
									<p className="text-gray-90 text-center">
										<T
											keyName={`fleet.${
												status === 'not_available'
													? 'fleetHasNoTrucksInProgress'
													: status === 'available'
													? 'fleetHasNoTrucksAvailable'
													: 'youHaventAddedAnyTrucks'
											}`}
										>
											{status === 'not_available'
												? "Your fleet has no trucks listed as 'In progress'"
												: status === 'available'
												? "Your fleet has no trucks listed as 'Available'"
												: 'You haven’t added any trucks to your fleet yet'}
										</T>
									</p>
								</SypacText>
								{status !== 'not_available' && status !== 'available' && (
									<>
										<SypacText variant="body-normal-small" className="mb-8">
											<p className="text-gray-40 text-center">
												<T keyName="fleet.addTrucksByClickingButton">
													Add trucks by clicking the 'Add truck' button
												</T>
											</p>
										</SypacText>
										<SypacButton
											variant="label"
											size="small"
											onClick={handleAddTruckModal}
										>
											<button
												type="button"
												className="flex justify-center items-center text-white bg-mountain-meadow transition hover:bg-mountain-meadow/80"
											>
												<span className="p-0 m-0 flex mr-[4px]">
													<PlusIcon color="white" />
												</span>
												<SypacText
													variant="body-normal-medium"
													className="mt-[4px]"
												>
													<p className="text-white text-center">
														<T keyName="fleet.addTruckButton">Add truck</T>
													</p>
												</SypacText>
											</button>
										</SypacButton>
									</>
								)}
							</div>
						)}
					</div>
				</div>
			)}

			<div className="w-[calc(100vw-703px)] mr-7.5 border border-solid border-gray-10 rounded-xl">
				{showMap && (
					<Map
						poligons={[]}
						markers={markers || []}
						setBaseRadius={setBaseRadius}
					/>
				)}
			</div>
			{showAddTruck ? (
				<ModalAddTruck
					isOpen={showAddTruck}
					truck={editableTruck}
					onClose={closeTruckModal}
					onSuccess={(code) => onSuccess(code)}
				/>
			) : null}
			{/* {showLogin ? (
				<ModalLogin
					isOpen={showLogin}
					onClose={handleLoginModal}
					onSuccess={() => {
						handleLoginModal();
						handleVerificationCodeModal();
					}}
				/>
			) : null} */}
			{showVerificationCode ? (
				<ModalVerificationCode
					isOpen={showVerificationCode}
					onClose={closeVerificationCodeModal}
					truck={editableTruck}
				/>
			) : null}
		</div>
	);
};

export default Fleet;
