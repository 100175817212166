import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
	CommonNotificationsEnums,
	NotificationCategory,
	NotificationInterface,
	OrderInterface,
	TransporterNotificationsEnums,
} from '../../../../../components/OrdersTable/interfaces/Order.interface';
import NotificationOrderAccepted from './Notification/NotificationOrderAccepted';
import NotificationAssignedTo from './Notification/NotificationAssignedTo';
import NotificationTruckAllocated from './Notification/NotificationTruckAllocated';
import NotificationDriverLoaded from './Notification/NotificationDriverLoaded';
import NotificationPendingArrivingUnloading from './Notification/NotificationPendingArrivingUnloading';
import NotificationDriverArrivedUnloading from './Notification/NotificationDriverArrivedUnloading';
import NotificationDriverUnloaded from './Notification/NotificationDriverUnloaded';
import NotificationUnloadingSuccessful from './Notification/NotificationUnloadingSuccessful';
import NotificationPendingPayment from './Notification/NotificationPendingPayment';
import NotificationDriverAccept from './Notification/NotificationDriverAccept';
import { SypacText } from '@sypac/component-library-react';
import NotificationPaid from './Notification/NotificationPaid';
import { useTranslate } from '@tolgee/react';
import NotificationStartTripToLoading from './Notification/NotificationStartTripToLoading';
import NotificationDriverArrivedLoading from './Notification/NotificationDriverArrivedLoading';
import NotificationDriverReportedIssue from './Notification/NotificationDriverReportedIssue';
import NotificationIssueSolved from './Notification/NotificationIssueSolved';
import { DriverIssuesEnum } from '../../../../../services/truck.services';

interface TimelineProps {
	selectedOrder: OrderInterface | undefined;
}

const Timeline: React.FC<TimelineProps> = (props) => {
	const { selectedOrder } = props;
	const { t } = useTranslate();
	const [notifications, setNotifications] = useState<NotificationInterface[]>(
		[],
	);

	useEffect(() => {
		const notificationList = selectedOrder?.notifications?.items?.length
			? JSON.parse(JSON.stringify(selectedOrder?.notifications?.items))
			: [];

		const relevantIssues = selectedOrder?.trucks?.[0]?.issues?.filter(
			(issue) =>
				issue.status === DriverIssuesEnum.IGNORE ||
				issue.status === DriverIssuesEnum.RESOLVE,
		);

		if (relevantIssues && relevantIssues.length > 0) {
			relevantIssues.forEach((issue) => {
				const matchingNotification = notificationList.find(
					(notification: { content: { comment: string }; category: string }) =>
						notification.content?.comment === issue.comment,
				);

				if (
					matchingNotification &&
					matchingNotification.category !==
						NotificationCategory.DRIVER_RESOLVE_ISSUE
				) {
					const formattedIssue = {
						content: issue,
						category:
							issue.status === DriverIssuesEnum.IGNORE
								? NotificationCategory.DISPATCHER_RESOLVE_ISSUE
								: NotificationCategory.DRIVER_CHANGE_TRUCK,
						createdAt: issue.updatedAt,
					};

					notificationList.unshift(formattedIssue as NotificationInterface);
				}
			});
		}

		notificationList.sort(
			(a: { createdAt: Date }, b: { createdAt: Date }) =>
				new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
		);

		setNotifications(notificationList);
	}, [selectedOrder]);

	return (
		<>
			{notifications.length ? (
				<div className="flex flex-col gap-7 px-10 py-7 border-0 border-solid border-t-[1px] border-gray-10">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-60">
							{t('awaitingTransport.timeline', 'Timeline')}
						</p>
					</SypacText>

					<div className="flex flex-col">
						{notifications.map((item, index) => {
							const date = dayjs(item.createdAt).format('DD MMM, YYYY — HH:mm');

							return (
								<div key={item.id}>
									{item.category === CommonNotificationsEnums.ORDER_PAID ? (
										<NotificationPaid notification={index === 0} date={date} />
									) : item.category ===
									  CommonNotificationsEnums.ORDER_COMPLETE ? (
										<NotificationPendingPayment
											notification={index === 0}
											date={date}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_CONFIRM_UNLOADING_GOODS ? (
										<NotificationUnloadingSuccessful
											notification={index === 0}
											client={selectedOrder?.createdByUser?.name}
											clientSigned={item.createdAt}
											clientDocuments={[]}
											driver={selectedOrder?.truck?.driverFullName}
											driverSigned={item.createdAt}
											driverDocuments={selectedOrder?.unloadDetails?.documents?.map(
												(src) => ({ src }),
											)}
											date={date}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS ? (
										<NotificationDriverUnloaded
											notification={index === 0}
											unloadingPlace={selectedOrder?.deliveryAddress}
											product={selectedOrder?.product}
											quantity={selectedOrder?.product.quantity}
											unloadingDate={item.createdAt}
											date={date}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_ARRIVED_TO_UNLOADING ? (
										<NotificationDriverArrivedUnloading
											notification={index === 0}
											loadingPlace={selectedOrder?.deliveryAddress}
											date={date}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_START_TRIP_TO_UNLOADING ? (
										<NotificationPendingArrivingUnloading
											notification={index === 0}
											date={date}
											loadingPlace={selectedOrder?.deliveryAddress}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_CONFIRM_LOADING_GOODS ? (
										<NotificationDriverLoaded
											notification={index === 0}
											date={date}
											loadingPlace={selectedOrder?.pickupAddress}
											product={selectedOrder?.product}
											quantity={selectedOrder?.product.quantity}
											loadingDate={item.createdAt}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_ARRIVED_TO_LOADING ? (
										<NotificationDriverArrivedLoading
											notification={index === 0}
											date={date}
											loadingPlace={selectedOrder?.pickupAddress!}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_START_TRIP_TO_LOADING ? (
										<NotificationStartTripToLoading
											notification={index === 0}
											date={date}
											eta={selectedOrder?.deliveryFrom!}
											truckType={selectedOrder?.truck?.type!}
											truckPlates={selectedOrder?.truck?.licensePlates!}
											driverName={selectedOrder?.truck?.driverFullName!}
											loadingPlace={selectedOrder?.pickupAddress!}
										/>
									) : item.category ===
									  TransporterNotificationsEnums.ORDER_ACCEPTED_BY_DRIVER ? (
										<NotificationDriverAccept
											notification={index === 0}
											date={date}
										/>
									) : item.category ===
									  TransporterNotificationsEnums.ORDER_ACCEPTED ? (
										<NotificationOrderAccepted
											notification={index === 0}
											date={date}
										/>
									) : item.category ===
									  TransporterNotificationsEnums.ORDER_ASSIGNED ? (
										<NotificationAssignedTo
											notification={index === 0}
											date={date}
											avatar={selectedOrder?.assigneeUser?.avatarUrl || ''}
											username={
												(selectedOrder?.assigneeUser?.profile?.firstName
													? `${selectedOrder?.assigneeUser?.profile?.firstName} ${selectedOrder?.assigneeUser?.profile?.lastName}`
													: selectedOrder?.assigneeUser?.name)!
											}
										/>
									) : item.category ===
									  TransporterNotificationsEnums.DRIVER_ALLOCATED ? (
										<NotificationTruckAllocated
											notification={index === 0}
											date={date}
											driverName={selectedOrder?.truck?.driverFullName!}
											truckName={selectedOrder?.truck?.name!}
											truckType={selectedOrder?.truck?.type!}
											truckPlates={selectedOrder?.truck?.licensePlates!}
											truckId={selectedOrder?.truck?.id.toString()!}
										/>
									) : item.category === NotificationCategory.NEW_ISSUE ? (
										<NotificationDriverReportedIssue
											notification={index === 0}
											date={date}
											content={item.content}
										/>
									) : item.category ===
									  NotificationCategory.DRIVER_RESOLVE_ISSUE ? (
										<NotificationIssueSolved
											notification={index === 0}
											date={date}
											solvedByDriver={true}
											content={item.content}
										/>
									) : item.category ===
									  NotificationCategory.DISPATCHER_RESOLVE_ISSUE ? (
										<NotificationIssueSolved
											notification={index === 0}
											date={date}
											solvedByDriver={false}
											content={item.content}
										/>
									) : item.category ===
									  NotificationCategory.DRIVER_CHANGE_TRUCK ? (
										<NotificationIssueSolved
											notification={index === 0}
											date={date}
											solvedByDriver={false}
											changeTruck={true}
											content={item.content}
										/>
									) : null}
								</div>
							);
						})}
					</div>
				</div>
			) : null}
		</>
	);
};

export default Timeline;
