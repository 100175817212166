import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import {
	CommonNotificationsEnums,
	OrderInterface,
} from '../../../../../components/OrdersTable/interfaces/Order.interface';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import Flag from 'react-world-flags';
import { MapIcon } from '../../../../../assets/MapIcon';
import { Calendar } from '../../../../../assets/Calendar';
import { Clock } from '../../../../../assets/Clock';
import { Person } from '../../../../../assets/Person';
import ProducerCompanyIcon from '../../../../../assets/ProducerCompanyIcon';
import classNames from 'classnames';
import DocumentsViewer from '../../../../../components/DocumentsViewer/DocumentsViewer';
import { OrderTarget } from '../../../../../components/OrdersTable/interfaces/OrderStatus.interface';
import {
	convertToTimeZone,
	deliveryDates,
} from '../../../../../utils/time.util';
import {
	DocumentsDTO,
	FileService,
} from '../../../../../services/file.service';
import ModalMap from '../../../../../components/ModalMap/ModalMap';
import dayjs from 'dayjs';
import { PhotoFrame } from '../../../../../assets/PhotoFrame';

interface PickUpDeliveryDetailsProps {
	selectedOrder: OrderInterface | undefined;
	target: string | undefined;
	isPickUp: boolean;
	simpleOrder: boolean;
}

const PickUpDeliveryDetails: React.FC<PickUpDeliveryDetailsProps> = (props) => {
	const { t } = useTranslate();
	const { selectedOrder, target, isPickUp, simpleOrder } = props;
	const [openDocView, setOpenDocView] = useState<boolean>(false);
	const [openMap, setOpenMap] = useState<boolean>(false);
	const [url, setUrl] = useState<string>();
	const [showBlur, setShowBlur] = useState<string>();
	const [docs, setDocs] = useState<DocumentsDTO[]>();

	const getDocsCallback = useCallback(async () => {
		try {
			if (
				selectedOrder?.id &&
				selectedOrder?.target &&
				selectedOrder.status !== 'requested'
			) {
				const { data } = await FileService.getFiles(
					selectedOrder.id,
					selectedOrder.target,
				);
				setDocs(data as any);
			}
		} catch (e) {
			console.log(e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOrder?.id, selectedOrder?.target]);

	useEffect(() => {
		getDocsCallback();
	}, [getDocsCallback]);

	const buyer = useMemo(() => {
		return selectedOrder?.createdByUser;
	}, [selectedOrder?.createdByUser]);
	const producer = useMemo(() => {
		return selectedOrder?.producer;
	}, [selectedOrder?.producer]);

	const openFile = (url: string) => {
		setUrl(url);
		setOpenDocView(true);
	};

	const handleImageBlur = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	const ImageCard = ({ imageUrl }: any) => (
		<div
			style={{ backgroundImage: `url(${imageUrl})` }}
			className={`relative flex flex-none w-[105px] bg-no-repeat bg-cover bg-center rounded-[12px] border border-solid border-gray-10 cursor-pointer ${
				showBlur === imageUrl ? 'hover:border-kimberly' : ''
			}`}
			onClick={() => openFile(imageUrl)}
			onMouseEnter={() => handleImageBlur(imageUrl)}
			onMouseLeave={() => handleImageBlur(undefined)}
		>
			{showBlur === imageUrl ? (
				<div
					className={classNames(
						'bg-black-50 absolute rounded-[11px] backdrop-blur-sm h-full w-full flex justify-center items-center',
						{},
					)}
				>
					<SypacIcon
						size="custom"
						width="32px"
						height="32px"
						icon-name="Magnifer"
						className="text-white"
					/>
				</div>
			) : null}
		</div>
	);

	const MultiplePickUp = () => {
		return selectedOrder?.notifications?.items?.some(
			(notify) =>
				notify.category === CommonNotificationsEnums.ORDER_ARRIVED_TO_LOADING,
		) ? (
			<div className="flex flex-col gap-2">
				{selectedOrder?.trucks?.map((orderTruck) => {
					const originalIndex = selectedOrder?.trucks?.findIndex(
						(doc) => doc.id === orderTruck.id,
					);
					const hasArrivalNotify = selectedOrder?.notifications?.items?.some(
						(notify) =>
							notify.category ===
								CommonNotificationsEnums.ORDER_ARRIVED_TO_LOADING &&
							notify.content?.driverId === orderTruck?.driver?.uid,
					);
					const hasLoadingNotify = selectedOrder?.notifications?.items?.some(
						(notify) =>
							notify.category ===
								CommonNotificationsEnums.ORDER_CONFIRM_LOADING_GOODS &&
							notify.content?.driverId === orderTruck?.driver?.uid,
					);

					if (hasArrivalNotify) {
						return (
							<div key={orderTruck?.id}>
								<div className="border-0 border-solid border-t-[1px] border-gray-10" />

								<div className="flex flex-col gap-5">
									<div className="flex gap-4 mt-5 px-5">
										<div className="flex items-center w-fit h-[22px] bg-gray-10 rounded-md px-[14px]">
											<SypacText variant="body-regular-medium">
												<p className="text-sm text-gray-90">
													<T keyName="requestedStep.payloadTruck">Truck</T>{' '}
													{originalIndex! + 1}
												</p>
											</SypacText>
										</div>
										<SypacText variant="body-regular-medium">
											<p className="text-gray-60">{orderTruck?.driver?.name}</p>
										</SypacText>
									</div>

									<div className="flex flex-col gap-3">
										{renderNotification(
											CommonNotificationsEnums.ORDER_ARRIVED_TO_LOADING,
											t(
												'deliverySchedule.truckArrivalConfirmation',
												'Truck arrival confirmation',
											),
											orderTruck?.driver?.uid,
										)}

										{hasLoadingNotify &&
											renderNotification(
												CommonNotificationsEnums.ORDER_CONFIRM_LOADING_GOODS,
												t(
													'clientDeliveryDetails.loadingConfirmation',
													'Loading confirmation',
												),
												orderTruck?.driver?.uid,
											)}
									</div>

									{orderTruck?.loadDetails &&
										Object.keys(orderTruck.loadDetails).length > 0 && (
											<>
												<div className="border-0 border-solid border-t-[1px] border-gray-10 mx-5" />
												<div className="flex flex-col gap-5 px-5">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-60">
															<T keyName="documentsTab.proofOfLoading">
																Proof of loading
															</T>
														</p>
													</SypacText>

													<div
														className={`flex gap-3 mb-4 ${
															docs?.[originalIndex!]?.loadDetails?.documents
																?.length! > 5
																? 'h-[120px] overflow-x-scroll overflow-y-hidden pb-1'
																: 'h-[105px]'
														}`}
													>
														{docs?.[
															originalIndex!
														]?.loadDetails?.documents?.map(
															(url: string, index: number) => (
																<ImageCard key={index} imageUrl={url} />
															),
														)}
													</div>
												</div>
											</>
										)}
								</div>
							</div>
						);
					}

					return null;
				})}
			</div>
		) : null;
	};

	const SimplePickUp = () => {
		return docs?.some((doc) => doc.loadDetails !== undefined) ? (
			<>
				<div className="border-0 border-solid border-t-[1px] border-gray-10" />
				<div className="flex flex-col gap-5 px-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-60">
							<T keyName="documentsTab.proofOfLoading">Proof of loading</T>
						</p>
					</SypacText>
					<div
						className={`flex gap-3 ${
							docs[0].loadDetails?.documents?.length > 5
								? 'h-[120px] overflow-x-scroll overflow-y-hidden pb-1'
								: 'h-[105px]'
						}`}
					>
						{docs[0].loadDetails?.documents?.map((url, index) => (
							<ImageCard key={index} imageUrl={url} />
						))}
					</div>
				</div>
			</>
		) : null;
	};

	const PickUpDocs = () => {
		return simpleOrder ? <SimplePickUp /> : <MultiplePickUp />;
	};

	const MultipleDelivery = () => {
		return selectedOrder?.notifications?.items?.some(
			(notify) =>
				notify.category === CommonNotificationsEnums.ORDER_ARRIVED_TO_UNLOADING,
		) ? (
			<div className="flex flex-col gap-2">
				{selectedOrder?.trucks?.map((orderTruck) => {
					const originalIndex = selectedOrder?.trucks?.findIndex(
						(doc) => doc.id === orderTruck.id,
					);
					const hasArrivalNotify = selectedOrder?.notifications?.items?.some(
						(notify) =>
							notify.category ===
								CommonNotificationsEnums.ORDER_ARRIVED_TO_UNLOADING &&
							notify.content?.driverId === orderTruck?.driver?.uid,
					);
					const hasLoadingNotify = selectedOrder?.notifications?.items?.some(
						(notify) =>
							notify.category ===
								CommonNotificationsEnums.ORDER_CONFIRM_UNLOADING_GOODS &&
							notify.content?.driverId === orderTruck?.driver?.uid,
					);

					if (hasArrivalNotify) {
						return (
							<div key={orderTruck?.id}>
								<div className="border-0 border-solid border-t-[1px] border-gray-10" />

								<div className="flex flex-col gap-5">
									<div className="flex gap-4 mt-5 px-5">
										<div className="flex items-center w-fit h-[22px] bg-gray-10 rounded-md px-[14px]">
											<SypacText variant="body-regular-medium">
												<p className="text-sm text-gray-90">
													<T keyName="requestedStep.payloadTruck">Truck</T>{' '}
													{originalIndex! + 1}
												</p>
											</SypacText>
										</div>
										<SypacText variant="body-regular-medium">
											<p className="text-gray-60">{orderTruck?.driver?.name}</p>
										</SypacText>
									</div>

									<div className="flex flex-col gap-3">
										{renderNotification(
											CommonNotificationsEnums.ORDER_ARRIVED_TO_UNLOADING,
											t(
												'deliverySchedule.truckArrivalConfirmation',
												'Truck arrival confirmation',
											),
											orderTruck?.driver?.uid,
										)}

										{hasLoadingNotify &&
											renderNotification(
												CommonNotificationsEnums.ORDER_CONFIRM_UNLOADING_GOODS,
												t(
													'clientDeliveryDetails.loadingConfirmation',
													'Loading confirmation',
												),
												orderTruck?.driver?.uid,
											)}
									</div>

									{orderTruck?.unloadDetails &&
										Object.keys(orderTruck.unloadDetails).length > 0 && (
											<>
												<div className="border-0 border-solid border-t-[1px] border-gray-10 mx-5" />
												<div className="flex flex-col gap-5 px-5">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-60">
															<T keyName="documentsTab.proofOfLoading">
																Proof of loading
															</T>
														</p>
													</SypacText>

													<div
														className={`flex gap-3 mb-4 ${
															docs?.[originalIndex!]?.unloadDetails?.documents
																?.length! > 5
																? 'h-[120px] overflow-x-scroll overflow-y-hidden pb-1'
																: 'h-[105px]'
														}`}
													>
														{docs?.[
															originalIndex!
														]?.unloadDetails?.documents?.map(
															(url: string, index: number) => (
																<ImageCard key={index} imageUrl={url} />
															),
														)}
													</div>
												</div>
											</>
										)}
								</div>
							</div>
						);
					}

					return null;
				})}
			</div>
		) : null;
	};

	const SimpleDelivery = () => {
		return docs?.some((doc) => doc.unloadDetails !== undefined) ? (
			<>
				<div className="border-0 border-solid border-t-[1px] border-gray-10" />
				<div className="flex flex-col gap-5 px-5">
					<div className="flex justify-between">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-60">
								<T keyName="documentsTab.proofOfDelivery">Proof of delivery</T>
							</p>
						</SypacText>
					</div>
					<div
						className={`flex gap-3 ${
							docs[0].unloadDetails?.documents?.length > 5
								? 'h-[120px] overflow-x-scroll overflow-y-hidden pb-1'
								: 'h-[105px]'
						}`}
					>
						{docs[0].unloadDetails?.documents?.map((url, index) => (
							<ImageCard key={index} imageUrl={url} />
						))}
					</div>
				</div>
			</>
		) : null;
	};

	const DeliveryDocs = () => {
		return simpleOrder ? <SimpleDelivery /> : <MultipleDelivery />;
	};

	const SellerInfo = () => {
		return (
			<div className="grid grid-cols-[250px,auto] items-start px-5">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-40">
						<T keyName="pickUpDeliveryDetails.seller">Seller:</T>
					</p>
				</SypacText>
				<div className="flex flex-col gap-2">
					<div className="flex gap-3 items-center">
						<span className="mr-[6px] scale-[1.1]">
							<ProducerCompanyIcon />
						</span>
						<SypacText variant="body-normal-medium">
							<p className="text-cornflower-blue w-[220px] truncate">
								{producer?.company?.name}
							</p>
						</SypacText>
						{/* {target === OrderTarget.admin && (
							<SypacButton variant="secondary" size="small">
								<button
									className="w-full border-none bg-transparent p-0 group"
									type="button"
								>
									<span className="absolute flex items-center -left-[8px]">
										<SypacIcon
											iconName="Document Add"
											size="custom"
											width="38px"
											height="38px"
											className="text-gray"
										/>
										<SypacText variant="body-normal-medium">
											<p className="text-cornflower-blue transition group-hover:text-gray-80">
												Edit
											</p>
										</SypacText>
									</span>
								</button>
							</SypacButton>
						)} */}
					</div>
				</div>
			</div>
		);
	};

	const BuyerInfo = () => {
		return (
			<div className="grid grid-cols-[250px,auto] items-start px-5">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-40">
						<T keyName="pickUpDeliveryDetails.buyer">Buyer:</T>
					</p>
				</SypacText>
				<div className="flex flex-col gap-2">
					<div className="flex gap-3 items-center">
						<span className="ml-[2px] mr-[6px] scale-[1.1]">
							<Person />
						</span>
						<SypacText variant="body-normal-medium">
							<p className="text-cornflower-blue w-[296px] truncate">
								{buyer?.company?.name}
							</p>
						</SypacText>
					</div>
				</div>
			</div>
		);
	};

	const renderNotification = (
		category: string,
		label: string,
		driverId?: string,
	) => {
		const notification = selectedOrder?.notifications?.items?.find(
			(notify) =>
				notify.category === category &&
				(driverId ? notify.content?.driverId === driverId : true),
		);

		if (!notification) return null;

		const { createdAt } = notification;
		const formattedDate = dayjs(createdAt).format('DD MMM. YYYY');
		const formattedTime = convertToTimeZone(createdAt);

		return (
			<div className="grid grid-cols-[250px,auto] items-start px-5">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-40">{label}</p>
				</SypacText>
				<div className="flex flex-col gap-3">
					<div className="flex gap-6 [&_p]:text-gray-80">
						<div className="flex gap-3">
							<span className="scale-[1.1]">
								<Calendar />
							</span>
							<SypacText variant="body-regular-medium">
								<p>{formattedDate}</p>
							</SypacText>
						</div>
						<div className="flex gap-3">
							<span className="scale-[1.1]">
								<Clock />
							</span>
							<SypacText variant="body-regular-medium">
								<p>{formattedTime}</p>
							</SypacText>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="bg-white border border-solid border-gray-10 rounded-xl">
				<SypacText variant="body-regular-medium" className="px-5 py-4">
					<p className="text-base text-gray-60">
						{isPickUp ? (
							<T keyName="pickUpDeliveryDetails.pickUp">Pick-up</T>
						) : (
							<T keyName="pickUpDeliveryDetails.delivery">Delivery</T>
						)}
					</p>
				</SypacText>

				<div className="flex flex-col gap-6 mb-3">
					<div className="grid grid-cols-[250px,auto] items-start px-5">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-40">
								{isPickUp ? (
									<T keyName="pickUpDeliveryDetails.loadingAddress">
										Loading address:
									</T>
								) : (
									<T keyName="pickUpDeliveryDetails.deliveryAddress:">
										Delivery address:
									</T>
								)}
							</p>
						</SypacText>
						<div className="flex flex-col gap-3">
							<div className="flex items-center gap-2">
								<span className="-ml-[2px] mt-[2px]">
									<Flag
										className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
										code={
											isPickUp
												? selectedOrder?.pickupCountryCode
												: selectedOrder?.deliveryCountryCode
										}
										width={24}
										height={16}
									/>
								</span>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 w-[296px] truncate">
										{isPickUp
											? selectedOrder?.pickupAddress
											: selectedOrder?.deliveryAddress}
									</p>
								</SypacText>
							</div>
							<div className="flex items-center gap-2">
								<span className="-ml-[2px] mr-[2px] scale-[1.2]">
									<MapIcon />
								</span>
								<SypacButton variant="secondary" size="small">
									<button
										className="w-full border-none bg-transparent p-0 group"
										onClick={() => setOpenMap(!openMap)}
										type="button"
									>
										<SypacText variant="body-normal-medium">
											<p className="text-cornflower-blue transition group-hover:text-gray-80">
												<T keyName="orderDetails.viewLocationOnMap">
													View location on the map
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>

							{!isPickUp && selectedOrder?.locationPhoto ? (
								<div className="flex items-center gap-2">
									<span className="mr-[2px] scale-[1.1]">
										<PhotoFrame />
									</span>
									<SypacButton variant="secondary" size="small">
										<button
											className="w-full border-none bg-transparent p-0 group"
											onClick={() => openFile(selectedOrder?.locationPhoto!)}
											type="button"
										>
											<SypacText variant="body-normal-medium">
												<p className="text-cornflower-blue transition group-hover:text-gray-80">
													<T keyName="orderDetails.viewLocationPhoto">
														View location photo
													</T>
												</p>
											</SypacText>
										</button>
									</SypacButton>
								</div>
							) : null}
						</div>
					</div>

					{(target === OrderTarget.admin ||
						target === OrderTarget.transporter) &&
						!!producer &&
						(isPickUp ? <SellerInfo /> : null)}
					{(target === OrderTarget.admin ||
						target === OrderTarget.transporter) &&
						(!isPickUp ? <BuyerInfo /> : null)}

					{selectedOrder?.deliveryIncluded ||
					(!selectedOrder?.deliveryIncluded && isPickUp) ? (
						<div className="grid grid-cols-[250px,auto] items-start px-5">
							<SypacText variant="body-regular-medium">
								<p className="text-gray-40">
									{isPickUp ? (
										<T keyName="pickUpDeliveryDetails.scheduledPickUpDate">
											Scheduled pick-up date:
										</T>
									) : (
										<T keyName="clientDeliveryDetails.deliveryDate">
											Scheduled delivery date:
										</T>
									)}
								</p>
							</SypacText>
							<div className="flex flex-col gap-3">
								<div className="flex gap-3 [&_p]:text-gray-80">
									<span className="scale-[1.1]">
										<Calendar />
									</span>
									{isPickUp
										? deliveryDates(
												selectedOrder?.trucks!,
												true,
												'deliveryFrom',
										  )
										: deliveryDates(selectedOrder?.trucks!, true, 'deliveryTo')}
								</div>
								<div className="flex gap-3 [&_p]:text-gray-80">
									<span className="scale-[1.1]">
										<Clock />
									</span>
									<div className="flex gap-5 items-center">
										{selectedOrder?.trucks ? (
											convertToTimeZone(
												selectedOrder.trucks[0].deliveryTimeFrom,
											) === '0:00' &&
											convertToTimeZone(
												selectedOrder.trucks[0].deliveryTimeTo,
											) === '23:59' ? (
												<SypacText variant="body-regular-medium">
													<p>Full Time</p>
												</SypacText>
											) : (
												<>
													<SypacText variant="body-regular-medium">
														<p>
															{convertToTimeZone(
																selectedOrder.trucks[0].deliveryTimeFrom,
															)}
														</p>
													</SypacText>
													<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
													<SypacText variant="body-regular-medium">
														<p>
															{convertToTimeZone(
																selectedOrder.trucks[0].deliveryTimeTo,
															)}
														</p>
													</SypacText>
												</>
											)
										) : null}
									</div>
								</div>
							</div>
						</div>
					) : null}

					{simpleOrder
						? isPickUp
							? selectedOrder?.notifications?.items?.some(
									(obj) =>
										obj.category ===
										CommonNotificationsEnums.ORDER_ARRIVED_TO_LOADING,
							  ) && (
									<div className="flex flex-col gap-3">
										{renderNotification(
											CommonNotificationsEnums.ORDER_ARRIVED_TO_LOADING,
											t(
												'deliverySchedule.truckArrivalConfirmation',
												'Truck arrival confirmation',
											),
										)}
										{renderNotification(
											CommonNotificationsEnums.ORDER_CONFIRM_LOADING_GOODS,
											t(
												'clientDeliveryDetails.loadingConfirmation',
												t(
													'clientDeliveryDetails.loadingConfirmation',
													'Loading confirmation',
												),
											),
										)}
									</div>
							  )
							: selectedOrder?.notifications?.items?.some(
									(obj) =>
										obj.category ===
										CommonNotificationsEnums.ORDER_ARRIVED_TO_UNLOADING,
							  ) && (
									<div className="flex flex-col gap-3">
										{renderNotification(
											CommonNotificationsEnums.ORDER_ARRIVED_TO_UNLOADING,
											t(
												'deliverySchedule.truckArrivalConfirmation',
												'Truck arrival confirmation',
											),
										)}
										{renderNotification(
											CommonNotificationsEnums.ORDER_CONFIRM_UNLOADING_GOODS,
											t(
												'clientDeliveryDetails.deliveryConfirmation',
												'Delivery confirmation',
											),
										)}
									</div>
							  )
						: null}

					{simpleOrder && isPickUp && !selectedOrder?.deliveryIncluded
						? selectedOrder?.notifications?.items?.some(
								(obj) =>
									obj.category ===
									CommonNotificationsEnums.ORDER_START_LOADING_GOODS,
						  ) && (
								<div className="flex flex-col gap-3">
									{renderNotification(
										CommonNotificationsEnums.ORDER_START_LOADING_GOODS,
										t(
											'deliverySchedule.truckArrivalConfirmation',
											'Truck arrival confirmation',
										),
									)}
									{renderNotification(
										CommonNotificationsEnums.ORDER_COMPLETE,
										t(
											'clientDeliveryDetails.loadingConfirmation',
											'Loading confirmation',
										),
									)}
								</div>
						  )
						: null}

					{isPickUp && target === OrderTarget.producer
						? selectedOrder?.notifications?.items?.some(
								(notify) =>
									notify.category ===
									CommonNotificationsEnums.ORDER_PRODUCER_LOADING_GOODS,
						  ) && (
								<div className="flex flex-col gap-3">
									{renderNotification(
										CommonNotificationsEnums.ORDER_PRODUCER_LOADING_GOODS,
										t(
											'clientDeliveryDetails.loadingConfirmation',
											'Loading confirmation',
										),
									)}
								</div>
						  )
						: null}

					{isPickUp ? <PickUpDocs /> : <DeliveryDocs />}

					{isPickUp && target === OrderTarget.producer
						? docs?.some((item) => item.producerLoadDetails !== undefined) && (
								<>
									<div className="border-0 border-solid border-t-[1px] border-gray-10" />
									<div className="flex flex-col gap-5 px-5">
										<SypacText variant="body-regular-medium">
											<p className="text-gray-60">
												<T keyName="documentsTab.proofOfLoading">
													Proof of loading
												</T>
											</p>
										</SypacText>
										<div
											className={`flex gap-3 mb-4 ${
												docs[0].producerLoadDetails?.documents?.length > 5
													? 'h-[120px] overflow-x-scroll overflow-y-hidden pb-1'
													: 'h-[105px]'
											}`}
										>
											{docs[0].producerLoadDetails?.documents?.map(
												(url: string, index: number) => (
													<ImageCard key={index} imageUrl={url} />
												),
											)}
										</div>
									</div>
								</>
						  )
						: null}

					{!selectedOrder?.deliveryIncluded &&
					isPickUp &&
					target &&
					simpleOrder &&
					[
						OrderTarget.customer?.toString(),
						OrderTarget.admin?.toString(),
					].includes(target) &&
					docs?.some((item) => item.producerLoadDetails !== undefined) ? (
						<>
							<div className="border-0 border-solid border-t-[1px] border-gray-10" />
							<div className="flex flex-col gap-5 px-5">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-60">
										<T keyName="documentsTab.proofOfLoading">
											Proof of loading
										</T>
									</p>
								</SypacText>

								{docs.map((doc, index) => (
									<div
										className={`flex gap-3 ${
											doc?.producerLoadDetails?.documents?.length > 5
												? 'h-[120px] overflow-x-scroll overflow-y-hidden pb-1'
												: 'h-[105px]'
										}`}
										key={index}
									>
										{doc?.producerLoadDetails?.documents?.map((url, index) => (
											<ImageCard key={index} imageUrl={url} />
										))}
									</div>
								))}
							</div>
						</>
					) : null}

					{!selectedOrder?.deliveryIncluded && !simpleOrder && isPickUp ? (
						<div className="flex flex-col gap-2">
							{docs
								?.filter(
									(orderTruck) =>
										orderTruck?.producerLoadDetails &&
										Object.keys(orderTruck.producerLoadDetails).length > 0,
								)
								.map((orderTruck) => {
									const originalIndex = docs.findIndex(
										(doc) => doc.id === orderTruck.id,
									);

									return (
										<div key={orderTruck?.id}>
											<div className="border-0 border-solid border-t-[1px] border-gray-10" />

											<div className="flex flex-col gap-5 px-5">
												<div className="flex gap-4 mt-5">
													<div className="flex items-center w-fit h-[22px] bg-gray-10 rounded-md px-[14px]">
														<SypacText variant="body-regular-medium">
															<p className="text-sm text-gray-90">
																<T keyName="requestedStep.payloadTruck">
																	Truck
																</T>{' '}
																{originalIndex + 1}
															</p>
														</SypacText>
													</div>
													<SypacText variant="body-regular-medium">
														<p className="text-gray-60">
															{orderTruck?.driver?.licensePlates}
														</p>
													</SypacText>
												</div>

												<div className="flex flex-col gap-5">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-60">
															<T keyName="documentsTab.proofOfDelivery">
																Proof of delivery
															</T>
														</p>
													</SypacText>

													<div
														className={`flex gap-3 mb-4 ${
															orderTruck.producerLoadDetails?.documents
																?.length > 5
																? 'h-[120px] overflow-x-scroll overflow-y-hidden pb-1'
																: 'h-[105px]'
														}`}
													>
														{orderTruck.producerLoadDetails?.documents?.map(
															(url: string, index: number) => (
																<ImageCard key={index} imageUrl={url} />
															),
														)}
													</div>
												</div>
											</div>
										</div>
									);
								})}
						</div>
					) : null}
				</div>
			</div>

			{openDocView ? (
				<DocumentsViewer
					url={url!}
					isPdf={false}
					isOpen={openDocView}
					onClose={() => setOpenDocView(false)}
					additional={
						selectedOrder?.locationPhoto
							? {
									isLocation: true,
									deliveryCode: selectedOrder?.deliveryCountryCode!,
									text: selectedOrder?.deliveryAddress!,
							  }
							: undefined
					}
				/>
			) : null}

			{openMap ? (
				<ModalMap
					isOpen={openMap}
					onClose={() => setOpenMap(false)}
					markers={[
						{
							latitude: isPickUp
								? selectedOrder?.pickupLocation?.coordinates?.at(1)!
								: selectedOrder?.deliveryLocation?.coordinates?.at(1)!,
							longitude: isPickUp
								? selectedOrder?.pickupLocation?.coordinates?.at(0)!
								: selectedOrder?.deliveryLocation?.coordinates?.at(0)!,
							variant: isPickUp ? 'pickup' : 'delivery',
							...(isPickUp
								? {
										pickup: {
											pickupAddress: selectedOrder?.pickupAddress!,
											pickupCountryCode: selectedOrder?.pickupCountryCode!,
										},
								  }
								: {
										delivery: {
											deliveryAddress: selectedOrder?.deliveryAddress!,
											deliveryCountryCode: selectedOrder?.deliveryCountryCode!,
										},
								  }),
						},
					]}
				/>
			) : null}
		</>
	);
};

export default PickUpDeliveryDetails;
